import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Table, Divider, Button, Modal, Form, Input, Select } from "antd";
import "./style.scss";

class Users extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = { visible: false };
		this.showModal = this.showModal.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}
	showModal() {
		this.setState({
			visible: true,
		});
	}
	handleOk() {
		this.setState({
			visible: false,
		});
	}
	handleCancel() {
		this.setState({
			visible: false,
		});
	}
	render(){
		const { visible } = this.state;
		const { Option } = Select;
		const usercolumns = [
			{
				title: "Name",
				dataIndex: "name",
			},
			{
				title: "Email",
				dataIndex: "email",
				// eslint-disable-next-line react/display-name
				render: useremail => <a href={`mailto:${useremail}`}>{useremail}</a>,
			},
			{
				title: "Phone Number",
				dataIndex: "number",
				// eslint-disable-next-line react/display-name
				render: usernumber => <a href={`tel:${usernumber}`}>{usernumber}</a>,
			},
			{
				title: "Role",
				dataIndex: "role",
			},
			{
				title: "Action",
				dataIndex: "action",
				// eslint-disable-next-line react/display-name
				render: () => (
					<span>
						<Button  type="primary" onClick={this.showModal}>Edit</Button>
						<Divider type="vertical" />
						<Button type="primary">Delete</Button>
					</span>
				),
			},
		];

		const userdata = [
			{
				key: "1",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Staff",
			},
			{
				key: "2",
				name: "Johnn",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Staff",
			},
			{
				key: "3",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "4",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "5",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "6",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "7",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "8",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "9",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "10",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "11",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "12",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "13",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "14",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "15",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "16",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "17",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "18",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "19",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "20",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "21",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
			{
				key: "22",
				name: "John",
				email: "john@gmail.com",
				number: "1234567890",
				role: "Admin",
			},
		];
		return (
			<div className="users_page page_content">
				<div className="user_title">
					<h1>All Users</h1>
				</div>
				<div className="user_listing">
					<Table columns={usercolumns} dataSource={userdata} pagination={{ pageSize: 20, }} />
					{/* edit user modal */}
					<Modal
						title="Edit User"
						centered
						visible={visible}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						footer={[
							<Button key="back" onClick={this.handleCancel}>
								Cancel
							</Button>,
							<Button key="submit" type="primary" onClick={this.handleOk}>
								Save
							</Button>,
						]}
					>
						<Form>
							<Form.Item name="name">
								<Input placeholder="Name" defaultValue={userdata[0].name} disabled />
							</Form.Item>
							<Form.Item name="email">
								<Input placeholder="Email" defaultValue={userdata[0].email} disabled />
							</Form.Item>
							<Form.Item name="number">
								<Input placeholder="Phone number" defaultValue={userdata[0].number} />
							</Form.Item>
							<Form.Item hasFeedback>
								<Select
									defaultValue={userdata[0].role}
									placeholder="Select a role"
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									<Option value="admin">Admin</Option>
									<Option value="staff">Staff</Option>
								</Select>
							</Form.Item>
						</Form>
					</Modal>
					{/* edit user modal */}
				</div>
			</div>
		);
	}
}

const translationWrapper = withTranslation()(Users);
export default withRouter(translationWrapper);
