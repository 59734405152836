import {
	PRODUCTS_LIST_START,
	PRODUCTS_LIST_SUCCESS,
	PRODUCTS_LIST_FALIED,
	PRODUCT_ADD_START,
	PRODUCT_ADD_SUCCESS,
	PRODUCT_ADD_FAILED,
	PRODUCT_DELETE_START,
	PRODUCT_DELETE_SUCCESS,
	PRODUCT_DELETE_FAILED,
	PRODUCT_EDIT_START,
	PRODUCT_EDIT_SUCCESS,
	PRODUCT_EDIT_FAILED,
} from "../actions/productsAction";

let initialState = {
	isLoading: false,
	loadingError: null,
	productsListData: [],
	membershipListData: [],
	productAddSuccess: false,
	getProductByIdSuccess: false,
	getProductById: null,
	productEditSuccess: false,
	productDeleteSuccess: false,
	fetchProductsSuccessfull: false
	// uploadZipSuccess: false
};

const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_LIST_START:
			return { ...state, isLoading: true, fetchProductsSuccessfull: false };
		case PRODUCTS_LIST_SUCCESS:
			return { ...state, isLoading: false, fetchProductsSuccessfull: true, productsListData: action.data };
		case PRODUCTS_LIST_FALIED:
			return { ...state, isLoading: false, loadingError: action.data };
		case PRODUCT_ADD_START:
			return { ...state, isLoading: true, productAddSuccess: false };
		case PRODUCT_ADD_SUCCESS:
			return { ...state, isLoading: false, productAddSuccess: true };
		case PRODUCT_ADD_FAILED:
			return { ...state, isLoading: false, loadingError: action.data };
		case PRODUCT_DELETE_START:
			return { ...state, isLoading: true, productDeleteSuccess: false };
		case PRODUCT_DELETE_SUCCESS:
			return { ...state, isLoading: false, productDeleteSuccess: true };
		case PRODUCT_DELETE_FAILED:
			return { ...state, isLoading: false, productDeleteSuccess: false };
		case PRODUCT_EDIT_START:
			return { ...state, isLoading: true, productEditSuccess: false };
		case PRODUCT_EDIT_SUCCESS:
			return { ...state, isLoading: false, productEditSuccess: true };
		case PRODUCT_EDIT_FAILED:
			return { ...state, isLoading: false, loadingError: action.data };
		default:
			return state;
	}
};
export default productsReducer;