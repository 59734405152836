import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import * as authService from "../services";
import { message } from "antd";
import { dialogAPIError } from "../../utils/dialog";
import * as moment from "moment";

// Product List
export const PRODUCTS_LIST_START = "PRODUCTS_LIST_START";
export const PRODUCTS_LIST_SUCCESS = "PRODUCTS_LIST_SUCCESS";
export const PRODUCTS_LIST_FALIED = "PRODUCTS_LIST_FALIED";

// Product Add
export const PRODUCT_ADD_START = "PRODUCT_ADD_START";
export const PRODUCT_ADD_SUCCESS = "PRODUCT_ADD_SUCCESS";
export const PRODUCT_ADD_FAILED = "PRODUCT_ADD_FAILED";

// Product Delete
export const PRODUCT_DELETE_START = "PRODUCT_DELETE_START";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAILED = "PRODUCT_DELETE_FAILED";

// Product Edit
export const PRODUCT_EDIT_START = "PRODUCT_EDIT_START";
export const PRODUCT_EDIT_SUCCESS = "PRODUCT_EDIT_SUCCESS";
export const PRODUCT_EDIT_FAILED = "PRODUCT_EDIT_FAILED";

// Product List Actions
export const productsListStart = () => ({
	type: PRODUCTS_LIST_START
});

export const productsListSuccess = (data) => ({
	type: PRODUCTS_LIST_SUCCESS,
	data
});

export const productsListFailed = (data) => ({
	type: PRODUCTS_LIST_FALIED,
	data
});

// Product Add Actions
export const productAddStart = () => ({
	type: PRODUCT_ADD_START
});

export const productAddSuccess = () => ({
	type: PRODUCT_ADD_SUCCESS
});

export const productAddFailed = (data) => ({
	type: PRODUCT_ADD_FAILED,
	data
});

// Product Delete
export const productDeleteStart = () => ({
	type: PRODUCT_DELETE_START
});

export const productDeleteSuccess = (data) => ({
	type: PRODUCT_DELETE_SUCCESS,
	data
});

export const productDeleteFailed = (data) => ({
	type: PRODUCT_DELETE_FAILED,
	data
});

// Product Edit Action
export const productEditStart = () => ({
	type: PRODUCT_EDIT_START
});

export const productEditSuccess = () => ({
	type: PRODUCT_EDIT_SUCCESS
});

export const productEditFailed = (data) => ({
	type: PRODUCT_EDIT_FAILED,
	data
});

export function fetchProductsList() {
	return async(dispatch) => {
		dispatch(productsListStart());
		try {
			let products = [];
			const querySnapshot = await authService.queryWithoutIsDeleted("products");
			if (!querySnapshot.empty) {
				querySnapshot.forEach(value => {
					products.push(value.data());
				});
				dispatch(productsListSuccess(products));
			} else {
				const error = "No data Found";
				dispatch(productsListSuccess([]));
				dispatch(productsListFailed({ error }));
			}
		} catch (error) {
			dispatch(productsListFailed(error));
			dialogAPIError(error);
		}
	};
}

export function addProduct(body) {
	return async(dispatch) => {
		dispatch(productAddStart());
		try {
			body["isDeleted"] = false;
			await authService.addData("products", body);
			dispatch(productAddSuccess());
			message.success("Product Added Successfully");
		} catch (error) {
			dispatch(productAddFailed(error));
			dialogAPIError(error);
		}
	};
}

export function deleteProduct(data) {
	return async(dispatch) => {
		dispatch(productDeleteStart());
		try {
			await authService.deleteProduct("products", data);
			dispatch(productDeleteSuccess());
			message.success("Product Deleted Successfully.");
		} catch (error) {
			dispatch(productDeleteFailed(error));
			dialogAPIError(error);
		}
	};
}

export function editProduct(data) {
	return async(dispatch) => {
		dispatch(productEditStart());
		try {
			await authService.updateProduct("products", data);
			dispatch(productEditSuccess());
			message.success("Product Edited Successfully");
		} catch (error) {
			dispatch(productEditFailed(error));
			dialogAPIError(error);
		}
	};
}

export function uploadImage(action, file, product) {
	return async(dispatch) => {
		dispatch(productEditStart());
		const storage = getStorage();
		let metadata = {
			contentType: "image/jpeg",
		};
		const storageRef = ref(storage, "images/" + moment().valueOf());
		const uploadTask = uploadBytesResumable(storageRef, file, metadata);
		uploadTask.on("state_changed",
			(snapshot) => {
				switch (snapshot.state) {
					case "paused":
						break;
					case "running":
						break;
				}
			}, (error) => {
				dispatch(productEditFailed(error));
			}, () => {
				getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
					product.url = downloadURL;
					if (action === "edit") {
						await authService.updateProduct("products", product);
						dispatch(productEditSuccess());
					}
					if (action === "add") {
						dispatch(productAddStart());
						await authService.addData("products", product);
						dispatch(productAddSuccess());
					}
				});
			});
	};
}