import { DASHBOARD_COUNT } from "../actions/commonActions";

let initialState = {};

const commonReducer = (state = initialState, action) => {
	switch (action.type) {
		case DASHBOARD_COUNT: {
			const { type, data } = action.payload;
			return { ...state, [type]: data };
		}
		default:
			return state;
	}
};
export default commonReducer;