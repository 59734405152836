import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

function NotFound() {
	return (
		<div className="not-found">
			<div className="section no-selectable">
				<h1 className="code">404</h1>
				<hr />
				<Link to="/">Return to Home Page</Link>
			</div>
		</div>
	);
}

export default NotFound;