import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as categoriesAction from "../../../../../redux/actions/categoriesAction";
import { Input, Modal, Form, Tag, Tooltip, Button } from "antd";
class EditCategoryModal extends PureComponent {
	constructor(props) {
		super(props);
		const { values } = this.props;
		this.state = {
			productCategory: values.productCategory,
			categorysTag: "",
			isEditOpen : false,
			subCategories: [],
			tags: [],
			inputVisible: false,
			editInputIndex: -1,
			editInputValue: "",
			inputValue: "",
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.inputRef = React.createRef();
		this.editInputRef = React.createRef();
		this.handleOk = this.handleOk.bind(this);
		this.handleAddMore = this.handleAddMore.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.showInput = this.showInput.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleEditInputConfirm = this.handleEditInputConfirm.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputConfirm = this.handleInputConfirm.bind(this);
	}
	handleReset(e) {
		e.preventDefault();
	}
	handleChange(e) {
		if (e.currentTarget.value.startsWith(" ")) {
			e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
		}
	}
	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { values, dispatchEditCategory, onCancel } = this.props;
		const { tags, } = this.state;
		form.validateFields((error, value) => {
			if (!error && value.productCategory !== undefined) {
				const data = {
					...value,
					values,
					subCategories : tags
				};
				values.productCategory = value.productCategory;
				dispatchEditCategory(data);
				onCancel();
			}
		});
	}
	handleOk(e) {
		e.preventDefault();
		const { form, dispatchCategoryAdd, onCancel } = this.props;
		const { tags } = this.state;
		form.validateFields((error, values) => {
			if (!error) {
				const data = {
					...values,
					values,
					subCategories : tags
				};
				dispatchCategoryAdd(data);
				setTimeout(() => {
					onCancel();
				}, 1000);
				form.resetFields();
			}
		});
	}
	handleAddMore(e) {
		e.preventDefault();
		const { form, } = this.props;
		const { subCategories } = this.state;
		form.validateFields((error, values) => {
			if (!error) {
				subCategories.push(values.productSubCategory);
			}
		});
	}
	handleClose(removedTag) {
		const { tags } = this.state;
		const newTags = tags.filter((tag) => tag !== removedTag);
		this.setState({
			tags: newTags
		});
	}
	showInput() {
		this.setState({
			inputVisible: true
		});
	}
	handleInputChange(e) {
		this.setState({
			inputValue: e.target
		});
		this.editInputRef.current;
	}
	handleInputConfirm() {
		const { tags, } = this.state;
		const { form, } = this.props;
		form.validateFields((error, values) => {
			if (!error) {
				if ( values.productTagCategory && tags.indexOf( values.productTagCategory) === -1) {
					this.setState(
						{
							tags: [...tags,  values.productTagCategory],
							inputVisible: false,
							categorysTag: "",
							isEditOpen: false
						}
					);
				}
			}
		});

	}

	handleEditInputConfirm() {
		const { form, } = this.props;
		form.validateFields((error, values) => {
			if (!error) {
				const { tags, editInputIndex, } = this.state;
				const newTags = [...tags];
				newTags[editInputIndex] = values.productTagCategory;
				this.setState({
					tags: newTags,
					categorysTag: "",
					isEditOpen: false,
					inputVisible: false
				});
			}
		});
	}
	componentDidMount(){
		const { inputVisible } = this.state;
		const {  values } = this.props;
		if (inputVisible) {
			this.inputRef.current;
		}
		(values.subCategories) && this.setState({
			tags : values.subCategories
		});
	}
	render() {
		const { visible, onCancel } = this.props;
		const { productCategory, inputVisible, tags, categorysTag, isEditOpen } = this.state;
		const { form: { getFieldDecorator }} = this.props;
		return (
			<div>
				<Modal
					title="Edit Category"
					centered
					visible={visible}
					onOk={this.handleSubmit}
					onCancel={onCancel}
					okText="Save"
				>
					<Form onSubmit={this.handleSubmit} onReset={this.handleReset}>
						<label>Category</label>
						<Form.Item hasFeedback>
							{getFieldDecorator("productCategory", {
								rules: [
									{ required: true, message: "Please input Category" },
									{ max: 40, message: "Input greater than 40 are not allowed" },
								],
								initialValue: productCategory
							})(

								<Input
									placeholder="Enter Category" onChange={this.handleChange}
								/>
							)}
						</Form.Item>
						<div>
							{
								tags && tags.length > 0 && (
									<div>
										<label>Sub category</label>
										<div className="subCategories">
											{tags.map((tag, index) => {
												const isLongTag = tags.length > 20;
												const tagElem = (
													<Tag
														key={tag}
														closable
														style={{ userSelect: "none" }}
														onClose={() => { this.handleClose(tag); }}
													>
														<span
															onDoubleClick={(e) => {
																if (index !== 0) {
																	this.setState(
																		{
																			editInputIndex: index,
																			categorysTag: tag,
																			inputVisible: true,
																			isEditOpen: true

																		}
																	);
																	e.preventDefault();
																}
															}}
														>
															{isLongTag ? `${tag.slice(0, 20)}...` : tag}
														</span>
													</Tag>
												);
												return isLongTag ? (
													<Tooltip title={tag} key={tag}>
														{tagElem}
													</Tooltip>
												) : (
													tagElem
												);
											})}
										</div>
									</div>

								)
							}
							{inputVisible ? (
								<>
									<label>sub category</label>
									<Form.Item name="productTagCategory" hasFeedback>
										{getFieldDecorator("productTagCategory", {
											rules: [
												{ required: true, message: "Category Name Required" },
												{ max: 40, message: "Input greater than 40 are not allowed" },
											],
											initialValue: categorysTag
										})(
											<Input placeholder="Category Tags"
												onChange={this.handleChange}
												onBlur={this.handleInputConfirm}
												onPressEnter={(isEditOpen) ?this.handleEditInputConfirm : this.handleInputConfirm} />,
										)}
									</Form.Item>
								</>
							) : (

								<Button
									onClick={this.showInput}
								>
										Add sub categories
								</Button>


							)}
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	categories: state.categoriesReducer
});

const mapDispatchToProps = {
	dispatchEditCategory: (data) => categoriesAction.editCategory(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditCategoryModal));