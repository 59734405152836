import * as authService from "../services";
import { message } from "antd";
import { dialogAPIError } from "../../utils/dialog";

// Category List
export const CATEGORIES_LIST_START = "CATEGORIES_LIST_START";
export const CATEGORIES_LIST_SUCCESS = "CATEGORIES_LIST_SUCCESS";
export const CATEGORIES_LIST_FALIED = "CATEGORIES_LIST_FALIED";

export const SUB_CATEGORIES_LIST_START = "SUB_CATEGORIES_LIST_START";
export const SUB_CATEGORIES_LIST_SUCCESS = "SUB_CATEGORIES_LIST_SUCCESS";
export const SUB_CATEGORIES_LIST_FALIED = "SUB_CATEGORIES_LIST_FALIED";
// Categgory Add
export const CATEGORY_ADD_START = "CATEGORY_ADD_START";
export const CATEGORY_ADD_SUCCESS = "CATEGORY_ADD_SUCCESS";
export const CATEGORY_ADD_FAILED = "CATEGORY_ADD_FAILED";

export const SUB_CATEGORY_ADD_START = "SUB_CATEGORY_ADD_START";
export const SUB_CATEGORY_ADD_SUCCESS = "SUB_CATEGORY_ADD_SUCCESS";
export const SUB_CATEGORY_ADD_FAILED = "SUB_CATEGORY_ADD_FAILED";
// Category Delete
export const CATEGORY_DELETE_START = "CATEGORY_DELETE_START";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAILED = "CATEGORY_DELETE_FAILED";

// Category Edit
export const CATEGORY_EDIT_START = "CATEGORY_EDIT_START";
export const CATEGORY_EDIT_SUCCESS = "CATEGORY_EDIT_SUCCESS";
export const CATEGORY_EDIT_FAILED = "CATEGORY_EDIT_FAILED";

// Category List Actions
export const categoriesListStart = () => ({
	type: CATEGORIES_LIST_START
});
export const subCategoriesListStart = () => ({
	type: SUB_CATEGORIES_LIST_START
});
export const categoriesListSuccess = (data) => ({
	type: CATEGORIES_LIST_SUCCESS,
	data
});
export const subCategoriesListSuccess = (data) => ({
	type: SUB_CATEGORIES_LIST_SUCCESS,
	data
});
export const categoriesListFailed = (data) => ({
	type: CATEGORIES_LIST_FALIED,
	data
});
export const subCategoriesListFailed = (data) => ({
	type: SUB_CATEGORIES_LIST_FALIED,
	data
});
// Category Add Actions
export const categoryAddStart = () => ({
	type: CATEGORY_ADD_START
});

export const categoryAddSuccess = () => ({
	type: CATEGORY_ADD_SUCCESS
});

export const categoryAddFailed = (data) => ({
	type: CATEGORY_ADD_FAILED,
	data
});

// Category Delete
export const categoryDeleteStart = () => ({
	type: CATEGORY_DELETE_START
});

export const categoryDeleteSuccess = (data) => ({
	type: CATEGORY_DELETE_SUCCESS,
	data
});

export const categoryDeleteFailed = (data) => ({
	type: CATEGORY_DELETE_FAILED,
	data
});

// Category Edit Action
export const categoryEditStart = () => ({
	type: CATEGORY_EDIT_START
});

export const categoryEditSuccess = () => ({
	type: CATEGORY_EDIT_SUCCESS
});

export const categoryEditFailed = (data) => ({
	type: CATEGORY_EDIT_FAILED,
	data
});

export function fetchCategoriesList() {
	return async(dispatch) => {
		dispatch(categoriesListStart());
		try {
			let categories = [];
			const querySnapshot = await authService.queryWithoutIsDeleted("categories");
			if (!querySnapshot.empty) {
				querySnapshot.forEach(doc => {
					categories.push(doc.data());
				});
				dispatch(categoriesListSuccess(categories));
			} else {
				const error = "No data Found";
				dispatch(categoriesListSuccess([]));
				dispatch(categoriesListFailed({ error }));
			}
		} catch (error) {
			dispatch(categoriesListFailed(error));
			dialogAPIError(error);
		}
	};
}
export function addCategory(body) {
	return async(dispatch) => {
		dispatch(categoryAddStart());
		try {
			body["isDeleted"] = false;
			await authService.addData("categories", body);
			dispatch(categoryAddSuccess());
			message.success("Category Added Successfully");
		} catch (error) {
			dispatch(categoryAddFailed(error));
			dialogAPIError(error);
		}
	};
}
export function addSubCategory(body) {
	return async(dispatch) => {
		dispatch(categoryAddStart());
		try {
			body["isDeleted"] = false;
			await authService.addCategoryData("categories", body);
			dispatch(categoryAddSuccess());
			message.success("Category Added Successfully");
		} catch (error) {
			dispatch(categoryAddFailed(error));
			dialogAPIError(error);
		}
	};
}
export function deleteCategory(data) {
	return async(dispatch) => {
		dispatch(categoryDeleteStart());
		try {
			await authService.deleteCategory("categories", data);
			dispatch(categoryDeleteSuccess());
			message.success("Category Deleted Successfully.");
		} catch (error) {
			dispatch(categoryDeleteFailed(error));
			dialogAPIError(error);
		}
	};
}

export function editCategory(data) {
	return async(dispatch) => {
		dispatch(categoryEditStart());
		try {
			await authService.updateCategory("categories", data);
			dispatch(categoryEditSuccess());
			message.success("Category Edit Successfully");
		} catch (error) {
			dispatch(categoryEditFailed(error));
			dialogAPIError(error);
		}
	};
}
