import React, { useEffect } from "react";
import { Layout, Menu, Dropdown, Button, Drawer, Icon } from "antd";
import { NavLink } from "react-router-dom";
import "./style.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

const userDetails = (
	<Menu className="user_info">
		<Menu.Item key="3">Sign out</Menu.Item>
	</Menu>
);

const sideMenudata = [
	{
		title: "Dashboard",
		link: "/dashboard",
		icon: "dashboard"
	},
	{
		title: "Orders",
		link: "/orders",
		icon: "shopping-cart"
	},
	{
		title: "Products",
		link: "/products",
		icon: "shop",
	},
	{
		title: "Categories",
		link: "/categories",
		icon: "",
	},
	{
		title: "Customers",
		link: "/customers",
		icon: "team"
	},
	{
		title: "Reports",
		link: "/reports",
		icon: "file-search",
		subMenus: [
			{
				title: "Sales By Products",
				link: "/reports/salesproducts",
				icon: ""
			},
			{
				title: "Sales By Customers",
				link: "/reports/salescustomers",
				icon: ""
			},
		]
	},
	{
		title: "Discounts",
		link: "/discounts",
		icon: "tags"
	},
	{
		title: "Notification",
		link: "/notification",
		icon: "notification"
	}
];


function Sidebar() {

	const [placement] = React.useState("left");
	const [current, setCurrent] = React.useState("1");
	const [visible, setVisible] = React.useState(false);

	function showDrawer() {
		setVisible(true);
	}

	function onClose(){
		setVisible(false);
	}
	function OnclickMenuLink(){
		setVisible(false);
	}

	useEffect(() => {
		switch (window.location.pathname) {
			case "/dashboard":
				return setCurrent("1");
			default:
				return setCurrent("1");
		}
	});

	const SidebarMenuItems = sideMenudata.map((menuitem, key) =>
		menuitem.subMenus ? (
			<SubMenu
				title={
					<span><Icon type={menuitem.icon} />{menuitem.title}</span>
				}
				key={key}
			>
				{
					menuitem.subMenus.map((item, key) => {
						return (
							<Menu.Item key={key}>
								<NavLink to={item.link}>{item.title}</NavLink>
							</Menu.Item>
						);
					})
				}
			</SubMenu>
		) : (
			<Menu.Item key={key}>
				<NavLink to={menuitem.link} onClick={OnclickMenuLink}><Icon type={menuitem.icon} />{menuitem.title}</NavLink>
			</Menu.Item>
		)
	);

	return (
		<div>
			<div className="responsive_menu">
				<Button type="primary" className="toggle_menu" onClick={showDrawer}><Icon type="menu" /></Button>
			</div>
			<Drawer
				placement={placement}
				closable={false}
				onClose={onClose}
				visible={visible}
				className="sidebar_drawer_menu">
				<Dropdown overlay={userDetails} trigger={["click"]}>
					<div className="user_toggle ant-dropdown-link">
						<div className="user_details">
							<h1>A</h1>
							<div className="user_name">
								<span>Admin</span>
							</div>
							<Icon type="down" />
						</div>
					</div>
				</Dropdown>
				<div className="sidebar_autoscroll">
					<Menu className="siderbar_menu" theme="light" mode="inline" selectedKeys={[current]}>{SidebarMenuItems}</Menu>
				</div>
			</Drawer>
			<Sider
				className="main_sidebar"
				style={{
					height: "100vh",
					position: "fixed",
					left: 0,
				}}>
				<div className="sidebar_autoscroll">
					<Menu className="siderbar_menu" theme="light" mode="inline" selectedKeys={[current]}>{SidebarMenuItems}</Menu>
				</div>
			</Sider>
		</div>
	);
}

export default Sidebar;
