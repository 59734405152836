/*eslint camelcase: [2, {properties: "never"}]*/
import axios from "axios";
import firebase from "../../utils/firebase";
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";
import { query as Query, collection, getFirestore, where, getDocs, addDoc, doc, updateDoc, deleteDoc, deleteField } from "firebase/firestore";

const db = getFirestore(firebase.app);

export function login(email, password) {
	return signInWithEmailAndPassword(firebase.auth, email, password);
}

export function register(email, password) {
	return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export function loginWithGoogle(email, password) {
	return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function logout(){
	return signOut(firebase.auth);
}

export async function query(collectionName) {
	const data = collection(db, collectionName);
	return await getDocs(data);
}

export function getDetail(collectionName, key, value) {
	const data = Query(collection(db, collectionName), where(key, "==", value));
	return data;
}

export async function queryWithoutIsDeleted(collectionName) {
	const data = Query(collection(db, collectionName), where("isDeleted", "==", false));
	return await getDocs(data);
}

export async function addData(collectionName, data) {
	const { uid } = firebase.auth.currentUser;
	let userData = {
		...data,
		uid
	};
	const insertResult = await addDoc(collection(db, collectionName), { uid });
	userData.id = insertResult.id;
	const getUpdateCollection = doc(db, collectionName, userData.id);
	const updateResult = await updateDoc(getUpdateCollection, userData);
	return updateResult;
}

export async function addCategoryData(collectionName, data) {
	const { uid } = firebase.auth.currentUser;
	let userData = {
		...data,
		uid
	};
	const insertResult = await addDoc(collection(db, collectionName), { uid });
	userData.id = insertResult.id;
	const getUpdateCollection = doc(db, collectionName, userData.id);
	await updateDoc(getUpdateCollection, userData);
}

export async function updateProduct(collectionName, data) {
	const getUpdateCollection = doc(db, collectionName, data.id);
	const updateResult = await updateDoc(getUpdateCollection, data);
	return updateResult;
}

export async function updateOrders(collectionName, data) {
	const getUpdateCollection = doc(db, collectionName, data.id);
	const updateResult = await updateDoc(getUpdateCollection, data);
	return updateResult;
}

export async function addDiscounts(collectionName, data) {
	const insertResult = await addDoc(collection(db, collectionName), data);
	return insertResult;
}
export async function deleteDiscountFields(collectionName, data) {
	const removeCapital = doc(db, collectionName, data.id);
	const deleteResult = await updateDoc(removeCapital, {
		discountType: deleteField(),
		discountAmount: deleteField()
	});
	return deleteResult;
}
export async function updateDiscount(collectionName, data) {
	const getUpdateCollection = doc(db, collectionName, data.id);
	const updateResult = await updateDoc(getUpdateCollection, data);
	return updateResult;
}

export async function updateCategory(collectionName, data) {
	const ref = doc(db, collectionName, data.values.id);
	await updateDoc(ref, {
		"subCategories": data.subCategories,
		"productCategory" : data.productCategory,
		isDeleted: false
	});
}

export async function deleteProduct(collectionName, data) {
	const deleteResult = await deleteDoc(doc(db, collectionName, data.id));
	return deleteResult;
}

export async function deleteCategory(collectionName, data) {
	const deleteResult = await deleteDoc(doc(db, collectionName, data.id));
	return deleteResult;
}

export function emailSendInstance() {
	return axios.create({
		baseURL: "https://us-central1-italian-dairy-product.cloudfunctions.net/api/"
	});
}

export function handleEmailSend(data){
	const requestInstance = emailSendInstance();
	return requestInstance.post("email/send-email", data);
}

export async function getUserOrderDetail(uid) {
	const db = firebase.firestore();
	const querySnapshot = await db.collection("users").where("uid", "==", uid).get();
	let user = {};
	querySnapshot.forEach((doc) => {
		if (doc.exists) {
			user = doc.data();
			return user;
		}
	});
	return user;
}

export async function getDiscountCodes() {
	const discount = collection(db, "discounts");
	return await getDocs(discount);
}

export function handleNotificationToAllDevices(data){
	const requestInstance = emailSendInstance();
	return requestInstance.post("notification/send-to-all-devices", data);
}

export function forgotPassword(email) {
	return sendPasswordResetEmail(firebase.auth, email);
}
