import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Row, Col, Layout, Icon } from "antd";
import { getDashboardCount } from "../../../redux/selectors";
import * as commonActions from "../../../redux/actions/commonActions";
import "./style.scss";
const { Sider, Content } = Layout;

const Home = ({ t, dispatchDashboardCount, dashboardCount }) => {
	const [ count, setCount ] = useState({});

	useEffect(() => {
		document.title = "IDP - Dashboard";
		dispatchDashboardCount();
	}, []);

	useEffect(() => {
		setCount(dashboardCount);
	}, [dashboardCount]);

	return (
		<div className="home_page page_content">
			<h4 className="page-title">Welcome home, Admin</h4>
			<div className="dashboard-count">
				<Row gutter={[16, 80]}>
					<Col className="gutter-row" lg={{ span: 6 }} sm={{ span: 3 }} xs={{ span: 1 }}>
						<div className="gutter-box">
							<Layout className="layout">
								<Sider className="icon">
									<Icon type="shopping-cart" />
								</Sider>
								<Content className="content">
									<p>{t("HomeScreen.OrdersLabel")}</p>
									<h3>{count.orders || 0}</h3>
								</Content>
							</Layout>
						</div>
					</Col>
					<Col className="gutter-row" lg={{ span: 6 }} sm={{ span: 3 }} xs={{ span: 1 }}>
						<div className="gutter-box">
							<Layout className="layout">
								<Sider className="icon">
									<Icon type="shop" />
								</Sider>
								<Content className="content">
									<p>{t("HomeScreen.ProductsLabel")}</p>
									<h3>{count.products || 0}</h3>
								</Content>
							</Layout>
						</div>
					</Col>
					<Col className="gutter-row" lg={{ span: 6 }} sm={{ span: 3 }} xs={{ span: 1 }}>
						<div className="gutter-box">
							<Layout className="layout">
								<Sider className="icon">
									<Icon type="bars" />
								</Sider>
								<Content className="content">
									<p>{t("HomeScreen.CategoriesLabel")}</p>
									<h3>{count.categories || 0}</h3>
								</Content>
							</Layout>
						</div>
					</Col>
					<Col className="gutter-row" lg={{ span: 6 }} sm={{ span: 3 }} xs={{ span: 1 }}>
						<div className="gutter-box">
							<Layout className="layout">
								<Sider className="icon">
									<Icon type="team" />
								</Sider>
								<Content className="content">
									<p>{t("HomeScreen.CustomersLabel")}</p>
									<h3>{count.customers || 0}</h3>
								</Content>
							</Layout>
						</div>
					</Col>
				</Row>
				<Row gutter={[16, 80]}>
					<Col className="gutter-row" lg={{ span: 6 }} sm={{ span: 3 }} xs={{ span: 1 }}>
						<div className="gutter-box">
							<Layout className="layout">
								<Sider className="icon">
									<Icon type="tags" />
								</Sider>
								<Content className="content">
									<p>{t("HomeScreen.DiscountsLabel")}</p>
									<h3>{count.discounts || 0}</h3>
								</Content>
							</Layout>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	dashboardCount: getDashboardCount(store),
});

const mapDispatchToProps = {
	dispatchDashboardCount: () => commonActions.fetchDashboardCount(),
};

const routerWrapper = withRouter(withTranslation()(Home));

export default connect(mapStateToProps, mapDispatchToProps)(routerWrapper);
