import * as authService from "../services";
import { dialogAPIError } from "../../utils/dialog";
import { message } from "antd";

export const CUSTOMERS_LIST_START = "CUSTOMERS_LIST_START";
export const CUSTOMERS_LIST_SUCCESS = "CUSTOMERS_LIST_SUCCESS";
export const CUSTOMERS_LIST_FALIED = "CUSTOMERS_LIST_FALIED";

export const customersListStart = () => ({
	type: CUSTOMERS_LIST_START
});

export const customersListSuccess = (data) => ({
	type: CUSTOMERS_LIST_SUCCESS,
	data
});

export const customersListFailed = (data) => ({
	type: CUSTOMERS_LIST_FALIED,
	data
});


export function fetchCustomersList() {
	return async(dispatch) => {
		dispatch(customersListStart());
		try {
			let users = [];
			const querySnapshot = await authService.query("users");
			if (!querySnapshot.empty) {
				querySnapshot.forEach(docs => {
					users.push(docs.data());
				});
				dispatch(customersListSuccess(users));
			} else {
				const error = "No data Found";
				dispatch(customersListFailed({ error }));
			}
		} catch (error) {
			dispatch(customersListFailed(error));
			dialogAPIError(error);
		}
	};
}
export function updateCustomerData(data) {
	return async(dispatch) => {
		try {
			await authService.updateDiscount("users", data)
				.then(() => {
					message.success("Discount data Updated Successfully");
				})
				.catch(error => dispatch(customersListFailed(error)));
		} catch (error) {
			dispatch(customersListFailed(error));
		}
	};
}
export function deleteDiscoutsData(data) {
	return async(dispatch) => {
		try {
			await authService.deleteDiscountFields("users", data)
				.then(() => {
					dispatch(fetchCustomersList());
					message.success("discounts data  Deleted Successfully");
				})
				.catch(error => dispatch(customersListFailed(error)));
		} catch (error) {
			dispatch(customersListFailed(error));
		}
	};
}

export function forgotPassword(email) {
	return async(dispatch) => {
		try {
			await authService.forgotPassword(email);
			message.success("Password reset link is sent to your email!");
		} catch (error) {
			dispatch(customersListFailed(error));
		}
	};
}