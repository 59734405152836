import React from "react";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import * as PropTypes from "prop-types";

function AdminLayout({ children }) {
	return (
		<div className="main">
			<Header />
			<Sidebar />
			{ children }
		</div>
	);
}

AdminLayout.propTypes = {
	children: PropTypes.any
};

export default AdminLayout;
