import "react-app-polyfill/ie9";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";
import "./i18n";
import App from "./App";
import "antd/dist/antd.css";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<React.Fragment>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
