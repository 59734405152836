import { combineReducers } from "redux";
import authReducer from "./authReducer";
import productsReducer from "./productsReducer";
import categoriesReducer from "./categoriesReducer";
import customersReducer from "./customersReducer";
import ordersReducer from "./ordersReducer";
import discountReducer from "./discoutReducer";
import commonReducer from "./commonReducer";

export default combineReducers({
	authReducer,
	productsReducer,
	categoriesReducer,
	ordersReducer,
	customersReducer,
	discountReducer,
	commonReducer
});