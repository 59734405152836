import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as authActions from "../../../redux/actions/authActions";
import history from "../../../redux/history";
import Cookies from "universal-cookie";
import { Form, Input, Button, Icon } from "antd";
import "./style.scss";

const cookies = new Cookies();

class Signin extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			validateTrigger: false
		};
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form, dispatchLogin } = this.props;
		form.validateFields((err, values) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				dispatchLogin(values);
			}
		});
	}

	componentDidUpdate(prevPros) {
		const { auth } = this.props;
		if (prevPros.auth.loggedIn === false && auth.loggedIn === true ){
			history.push("/dashboard");
		}
	}

	componentDidMount() {
		// this will check if user is already logged in, then it will redirect to dashboard page
		if (cookies.get("user")) {
			history.push("/dashboard");
		}
	}

	render(){
		const { email, password, validateTrigger } = this.state;
		const { form: { getFieldDecorator }, auth: { isLoading }, t } = this.props;
		return (
			<div className="page-login">
				<div className="login-section">
					<img className="logo" src="/assets/images/logo-black.png" />
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="login-form">
						<Form.Item>
							{getFieldDecorator("email", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ type: "email", message: t("Login.Errors.EmailInvalid") },
									{ required: true, message: t("Login.Errors.EmailRequired") }
								],
								initialValue: email
							})(
								<Input
									prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
									placeholder={t("Login.Email")}
									autoComplete="email"
								/>,
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator("password", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: t("Login.Errors.PasswordRequired") },
								],
								initialValue: password
							})(
								<Input
									prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
									type="password"
									placeholder={t("Login.Password")}
									autoComplete="new-password"
								/>,
							)}
						</Form.Item>
						<Form.Item className="button-section">
							<Button type="primary" htmlType="submit" loading={isLoading}>
								{t("Login.Signin")}
							</Button>
						</Form.Item>
					</Form>
					{/* <div className="signup_signin">
						<span>Not Register Yet ? <Link to="/signup">Signup Here!</Link></span>
					</div> */}
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	auth: state.authReducer
});

const mapDispatchToProps = {
	dispatchLogin: (data) => authActions.handleLogIn(data)
};

const routerWrapper = withRouter(withTranslation()(Signin));

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(routerWrapper));