// import axios from "axios";
// import Cookies from "universal-cookie";
// import * as Config from "../../config";

// const cookies = new Cookies();

// export function unAuthenticatedInstance(){
// 	return axios.create({
// 		baseURL: Config.API_URL
// 	});
// }

// export function authenticatedInstance(){
// 	return axios.create({
// 		baseURL: Config.API_URL,
// 		headers: {
// 			"Authorization": "Bearer"+" "+cookies.get("authToken")
// 		}
// 	});
// }

import axios from "axios";
import Cookies from "universal-cookie";
import * as Config from "../../config";

const cookies = new Cookies();

export function unAuthenticatedInstance(){
	return axios.create({
		baseURL: Config.API_URL
	});
}

export function authenticatedInstance(){
	return axios.create({
		baseURL: Config.API_URL,
		headers: {
			"Authorization": "Bearer"+" "+cookies.get("authToken")
		}
	});
}

function getRoute(action, query = null) {
	switch (action) {
		case "login":
			return "/api/admin/login";
		case "get":
			return "/api/admin/get?"+query;
	}
}

export function apiRequestGet(action, query = null) {
	const requestInstance = authenticatedInstance();
	let route = getRoute(action, query);
	return requestInstance.get(route);
}

export function apiRequestPost(data, action) {
	const requestInstance = authenticatedInstance();
	let route = getRoute(action);
	return requestInstance.post(route, data);
}

export function unAuthenticatedApiRequestGet(action) {
	const requestInstance = unAuthenticatedInstance();
	let route = getRoute(action);
	return requestInstance.get(route);
}

export function unAuthenticatedApiRequestPost(data, action) {
	const requestInstance = unAuthenticatedInstance();
	let route = getRoute(action);
	return requestInstance.post(route, data);
}