import Cookies from "universal-cookie";
import { message } from "antd";
import * as authService from "../services";
import { dialogAPIError, dialogError } from "../../utils/dialog";
import { getDocs } from "firebase/firestore";

export const LOG_IN_START = "LOG_IN_START";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const LOG_OUT = "LOG_OUT";
export const GOOGLE_LOG_IN_START = "GOOGLE_LOG_IN_START";
export const GOOGLE_LOG_IN_STOP = "GOOGLE_LOG_IN_STOP";

const cookies = new Cookies();

/* LOGIN ACTIONS */
export const logInStart = () => ({
	type: LOG_IN_START
});

export const logInSuccess = (data) => ({
	type: LOG_IN_SUCCESS,
	data
});

export const logInFailed = (error) => ({
	type: LOG_IN_FAILED,
	error
});

/* REGISTER ACTIONS */
export const registerStart = () => ({
	type: REGISTER_START
});

export const registerSuccess = (data) => ({
	type: REGISTER_SUCCESS,
	data
});

export const registerFailed = (error) => ({
	type: REGISTER_FAILED,
	error
});

export const logOut = () => ({
	type: LOG_OUT
});

export const googleLogInStart = () => ({
	type: GOOGLE_LOG_IN_START
});

export const googleLogInStop = () => ({
	type: GOOGLE_LOG_IN_STOP
});

export function handleLogIn(data) {
	return async(dispatch) => {
		dispatch(logInStart());
		try {
			const response = await authService.login(data.email, data.password);
			const querySnapshot = authService.getDetail("users", "uid", response.user.uid);
			const detail = await getDocs(querySnapshot);
			let user = {};
			if (detail.empty) {
				const error = "Invalid user";
				authService.logout();
				dispatch(logInFailed({ error }));
				dialogError(error);
				return;
			}
			detail.forEach((doc) => {
				if (doc.exists) {
					user = doc.data();
					if (user.type === "admin") {
						cookies.set("user", user);
						cookies.set("authToken", user.uid);
						dispatch(logInSuccess(user));
						message.success("Logged in");
					} else {
						const error = "Invalid user";
						authService.logout();
						dispatch(logInFailed({ error }));
						dialogError(error);
					}
				} else {
					const error = "Invalid user";
					authService.logout();
					dispatch(logInFailed({ error }));
					dialogError(error);
				}
			});
		} catch (error) {
			dispatch(logInFailed(error));
			dialogAPIError(error);
		}
	};
}

export function handleRegister(data) {
	return (dispatch) => {
		dispatch(registerStart());
		authService.register(data).then(function(response) {
			cookies.set("authToken", response.data.token);
			dispatch(registerSuccess(response.data));
		}).catch(function(error) {
			dispatch(registerFailed(error));
			dialogAPIError(error);
		});
	};
}

export function handleGoogleLogIn(data) {
	return (dispatch) => {
		dispatch(googleLogInStart());
		authService.loginWithGoogle(data).then(function(response) {
			dispatch(googleLogInStop());
			cookies.set("authToken", response.data.data.token);
			dispatch(logInSuccess(response.data));
		}).catch(function(error) {
			dispatch(googleLogInStop());
			dialogAPIError(error);
		});
	};
}

export function handleLogOut() {
	localStorage.clear();
	cookies.remove("authToken");
}
