import { Modal } from "antd";
import i18n from "../i18n";

export function dialogSuccess(title, content) {
	Modal.success({
		title,
		content,
	});
}

export function dialogError(title, content) {
	Modal.error({
		title,
		content,
	});
}

export function dialogWarning(title, content) {
	Modal.warning({
		title,
		content,
	});
}

export function dialogAPIError(error) {
	let errorMsg = (error.response && error.response.data && error.response.data.error) ? error.response.data.message : i18n.t("Common.ErrorMessage");
	Modal.error({
		title: i18n.t("Common.ErrorTitle"),
		content: errorMsg,
		okText: i18n.t("Common.Ok"),
		className: "error-dialog"
	});
}