import React from "react";
import { Icon, Dropdown, Menu } from "antd";
import { withTranslation } from "react-i18next";
import { handleLogOut } from "../../redux/actions/authActions";
import "./style.scss";

function logOut() {
	handleLogOut();
	window.location.reload();
}


function Header({ t }) {
	return (
		<div className="header">
			<div className="logo">
				<img src="/assets/images/logo-black.png" />
			</div>
			<div className="user_dropdown">
				<Dropdown
					overlay={() =>
						<Menu className="user_info">
							<Menu.Item key="1" onClick={logOut}>{t("Common.Signout")}</Menu.Item>
						</Menu>
					}
					trigger={["click"]}
				>
					<div className="user_toggle ant-dropdown-link">
						<div className="user_details">
							<h1>A</h1>
							<div className="user_name">
								<span>Admin</span>
							</div>
							<Icon type="down" />
						</div>
					</div>
				</Dropdown>
			</div>
		</div>
	);
}

export default withTranslation()(Header);