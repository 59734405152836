import * as authService from "../services";
import { message } from "antd";
import { dialogAPIError, dialogSuccess } from "../../utils/dialog";
import { saveAs } from "file-saver";

export const ORDERS_LIST_START = "ORDERS_LIST_START";
export const ORDERS_LIST_SUCCESS = "ORDERS_LIST_SUCCESS";
export const ORDERS_LIST_FALIED = "ORDERS_LIST_FALIED";
export const USER_ORDER_DETAIL_START = "USER_ORDER_DETAIL_START";
export const USER_ORDER_DETAIL_SUCCESS = "USER_ORDER_DETAIL_SUCCESS";
export const USER_ORDER_DETAIL_FAILED = "USER_ORDER_DETAIL_FAILED";

export const ordersListStart = () => ({
	type: ORDERS_LIST_START
});

export const ordersListSuccess = (data) => ({
	type: ORDERS_LIST_SUCCESS,
	data
});

export const ordersListFailed = (data) => ({
	type: ORDERS_LIST_FALIED,
	data
});

export const userOrdersDetailsStart = () => ({
	type: USER_ORDER_DETAIL_START
});

export const userOrdersDetailsSuccess = (data) => ({
	type: USER_ORDER_DETAIL_SUCCESS,
	data
});

export const userOrdersDetailsFailed = (data) => ({
	type: USER_ORDER_DETAIL_FAILED,
	data
});

export function fetchOrdersList() {
	return async(dispatch) => {
		dispatch(ordersListStart());
		try {
			let orders = [];
			const querySnapshot = await authService.query("orders");
			if (!querySnapshot.empty) {
				querySnapshot.forEach(value => {
					let data = value.data();
					data.id = value.id;
					orders.push(data);
				});
				dispatch(ordersListSuccess(orders));
			} else {
				const error = "No data Found";
				dispatch(ordersListFailed({ error }));
			}
		} catch (error) {
			dispatch(ordersListFailed(error));
			dialogAPIError(error);
		}
	};
}
export function orderStatusUpdate(data) {
	return async(dispatch) => {
		try {
			delete data.index;
			await authService.updateOrders("orders", data)
				.then(() => {
					dispatch(fetchOrdersList());
					message.success("Order Updated Successfully");
				})
				.catch(error => dispatch(ordersListFailed(error)));
		} catch (error) {
			dispatch(ordersListFailed(error));
		}
	};
}

export function fetchUserOrderDetail(uid) {
	return async(dispatch) => {
		try {
			dispatch(userOrdersDetailsStart());
			await authService.getUserOrderDetail(uid).then((res) => {
				dispatch(userOrdersDetailsSuccess(res));
			}).catch(error => {
				dispatch(userOrdersDetailsFailed(error));
			});
		} catch (error) {
			dispatch(userOrdersDetailsFailed(error));
		}
	};
}

const b64toBlob = (b64Data, contentType = "", sliceSize=512) => {
	const byteCharacters = window.atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};


export function sendEmail(data) {
	return async() => {
		await authService.handleEmailSend(data).then(function(res) {
			if (data.isWeb) {
				const contentType = "application/pdf";
				const blob = b64toBlob(res.data.data, contentType);
				saveAs(blob, `${data.orderNo}.pdf`);

				dialogSuccess("Success", "Download successfully!");
			} else {
				dialogSuccess("Success", "Email has been sent successfully!");
			}
		}).catch(function(error) {
			dialogAPIError(error);
		});
	};
}