import {
	CATEGORIES_LIST_START,
	CATEGORIES_LIST_SUCCESS,
	CATEGORIES_LIST_FALIED,
	CATEGORY_ADD_START,
	CATEGORY_ADD_SUCCESS,
	CATEGORY_ADD_FAILED,
	CATEGORY_DELETE_START,
	CATEGORY_DELETE_SUCCESS,
	CATEGORY_DELETE_FAILED,
	CATEGORY_EDIT_START,
	CATEGORY_EDIT_SUCCESS,
	CATEGORY_EDIT_FAILED,
	SUB_CATEGORIES_LIST_SUCCESS,
} from "../actions/categoriesAction";

let initialState = {
	isLoading: false,
	loadingError: null,
	categoriesListData: [],
	subCategoriesListData: [],
	membershipListData: [],
	categoryAddSuccess: false,
	getCategoryByIdSuccess: false,
	getCategoryById: null,
	categoryEditSuccess: false,
	categoryDeleteSuccess: false,
};

const categoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case CATEGORIES_LIST_START:
			return { ...state, isLoading: true };
		case CATEGORIES_LIST_SUCCESS:
			return { ...state, isLoading: false, categoriesListData: action.data };
		case SUB_CATEGORIES_LIST_SUCCESS:
			return { ...state, isLoading: false, subCategoriesListData: action.data };
		case CATEGORIES_LIST_FALIED:
			return { ...state, isLoading: false, loadingError: action.data };
		case CATEGORY_ADD_START:
			return { ...state, isLoading: true, categoryAddSuccess: false };
		case CATEGORY_ADD_SUCCESS:
			return { ...state, isLoading: false, categoryAddSuccess: true };
		case CATEGORY_ADD_FAILED:
			return { ...state, isLoading: false, loadingError: action.data };
		case CATEGORY_DELETE_START:
			return { ...state, isLoading: true, categoryDeleteSuccess: false };
		case CATEGORY_DELETE_SUCCESS:
			return { ...state, isLoading: false, categoryDeleteSuccess: true };
		case CATEGORY_DELETE_FAILED:
			return { ...state, isLoading: false, categoryDeleteSuccess: false };
		case CATEGORY_EDIT_START:
			return { ...state, isLoading: true, categoryEditSuccess: false };
		case CATEGORY_EDIT_SUCCESS:
			return { ...state, isLoading: false, categoryEditSuccess: true };
		case CATEGORY_EDIT_FAILED:
			return { ...state, isLoading: false, loadingError: action.data };
		default:
			return state;
	}
};
export default categoriesReducer;