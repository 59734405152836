import {
	LOG_IN_START,
	LOG_IN_SUCCESS,
	LOG_IN_FAILED,
	REGISTER_START,
	REGISTER_SUCCESS,
	REGISTER_FAILED,
	LOG_OUT,
	GOOGLE_LOG_IN_START,
	GOOGLE_LOG_IN_STOP
} from "../actions/authActions";

// Get loggedin boolean from localstorage
import Cookies from "universal-cookie";

const cookies = new Cookies();
const isLogin = cookies.get("authToken");

let initialState = {
	isLoading: false,
	loadingError: null,
	loggedIn: isLogin ? true: false,
	loadingGoogle: false,
	loadingFacebook: false
};

const authReducer = (state = initialState, action) => {
	switch (action.type){
		case LOG_IN_START:
			return { ...state, isLoading: true };
		case LOG_IN_FAILED:
			return { ...state, isLoading: false, loadingError: action.error };
		case LOG_IN_SUCCESS:
			return { ...state, isLoading: false, loggedIn: true, loginResponse: action.data };
		case REGISTER_START:
			return { ...state, isLoading: true };
		case REGISTER_FAILED:
			return { ...state, isLoading: false, loadingError: action.error };
		case REGISTER_SUCCESS:
			return { ...state, isLoading: false, loggedIn: true, loginResponse: action.data };
		case GOOGLE_LOG_IN_START:
			return { ...state, loadingGoogle: true };
		case GOOGLE_LOG_IN_STOP:
			return { ...state, loadingGoogle: false };
		case LOG_OUT:
			return { ...initialState };
		default:
			return state;
	}
};

export default authReducer;