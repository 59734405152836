import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Input, Button, Switch, Table, Spin, Form, message } from "antd";
import { connect } from "react-redux";
import moment from "moment-timezone";
import * as discount from "../../../redux/actions/discountAction";

import "./style.scss";

class Discounts extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			showDiscountFields: false,
			discountcode: "",
			type: "$",
			discountAmount: ""
		};
	}
	componentDidMount() {
		document.title = "IDP - Discounts";
		const { dispatchDiscountCodeList } = this.props;
		dispatchDiscountCodeList();
	}
	addDiscoutShow() {
		this.setState({ showDiscountFields: true });
	}
	switchChange(e) {
		if (e) {
			this.setState({ type: "%" });
		} else {
			this.setState({ type: "$" });
		}
	}
	generateCode() {
		const { form } = this.props;

		const code = Math.random().toString(36).substr(2, 6).toUpperCase();
		form.setFieldsValue({
			discountcode: `${code}`,
		});
	}
	onChangeAlphaNumericInput(e) {
		const value = e.target.value;
		const regex = /^[0-9a-zA-Z(\-)]+$/; //this will admit letters, numbers and dashes
		if (value.match(regex) || value === "") {
			this.setState({ discountcode: value.toUpperCase() });
		}
	}
	saveDiscount() {
		const { type } = this.state;
		const { form, discount, dispatchDiscountCreate } = this.props;
		form.validateFields((error, value) => {
			if (!error) {
				/* eslint-disable no-unused-vars */
				const existIngCode = discount.DiscountCodeList.filter(el => el.discountCode === value.discountcode);
				let upper = 0;
				let number = 0;
				let lower = 0;
				let special = 0;
				for (let i = 0; i < value.discountcode.length; i++) {
					if (value.discountcode[i] >= "A" && value.discountcode[i] <= "Z") {
						upper++;
					} else if (value.discountcode[i] >= "a" && value.discountcode[i] <= "z") {
						lower++;
					}
					else if (value.discountcode[i] >= "0" && value.discountcode[i] <= "9") {
						number++;
					}
					else  {
						special++;
					}
				}
				if (special) {
					form.setFields({
						discountcode: {
							value: value.discountcode,
							errors: [new Error("special character not allow")],
						},
					});
					return;
				}
				if (!existIngCode.length) {
					let createdDate = moment().tz("Asia/Dubai");
					const data = {
						discountCode: value.discountcode.toUpperCase(),
						discountType: type,
						discountAmount: value.discountAmount,
						createdDate: createdDate.format("DD MMMM YYYY HH:mm:ss z"),
						isEnable: true
					};
					dispatchDiscountCreate(data);
					this.setState({ ...this.state, discountcode: "", discountAmount: "", type: "$", showDiscountFields: false });
				} else {
					message.error("Discount code already exist!");
				}
			}
		});
	}
	selectRowChange(e, selectRow) {
		selectRow.isEnable = e;
		const { dispatchUpdateDiscount } = this.props;
		dispatchUpdateDiscount(selectRow);
	}
	filter(data) {
		data.sort(function(x, y){
			return new Date(y.createdDate) - new Date(x.createdDate);
		});
		return data;
	}

	render() {
		const { showDiscountFields, discountAmount } = this.state;
		const { form: { getFieldDecorator }, discount } = this.props;
		const { Column } = Table;
		return (
			<div className="page_content">
				<div className="product_title">
					<h1>Discounts</h1>
					<Button disabled={showDiscountFields} type="primary" className="edit_btn" onClick={() => this.addDiscoutShow()}>Add Discount code</Button>
				</div>
				{showDiscountFields && <div className="Discount_fields">
					<Form onSubmit={this.saveDiscount} className="Discount_fields">
						<div className="discountcode_generatecode">
							<span>Discount Code</span>
							<div className="discount_code">
								<Form.Item name="discountcode">
									{getFieldDecorator("discountcode", {
										rules: [
											{ required: true, message: "discountcode Required" },
										],
									})(
										<Input placeholder="discount Code" pattern={/^[0-9A-Z]+$/} onChange={(e) => this.onChangeAlphaNumericInput(e)} />,
									)}
								</Form.Item>
								<div className="generate_code"><span> </span><Button onClick={() => this.generateCode()}>Generate Code </Button></div>
							</div>
						</div>
						<div className="type_amount">
							<div className="discount_type">
								<span>Type</span>
								<div className="inner_type">
									<span>$</span>
									<Switch checkedChildren="" unCheckedChildren="" onChange={(e) => this.switchChange(e)} />
									<span>%</span>
								</div>
							</div>
							<div className="discount_amount">
								<span>Discount Amount</span>
								<Form.Item name="discountAmount">
									{getFieldDecorator("discountAmount", {
										rules: [
											{ required: true, message: "discountAmount Required" }
										],
										initialValue: discountAmount
									})(
										<Input placeholder="discount Amount" type="number" onChange={(e) => this.setState({ discountAmount: e.target.value })} />
									)}
								</Form.Item>
							</div>
						</div>
						<div className="buttons">
							<span className="hidden">Hidden text</span>
							<Button type="submit" onClick={() => this.saveDiscount()}>Save</Button>
							<Button type="primary" onClick={() => this.setState({ showDiscountFields: false, discountcode: "", discountAmount: "",  })}>Cancel</Button>
						</div>
					</Form>
				</div>}
				<div>
					<Spin tip="Please wait" spinning={discount.isLoading} size={"large"}>
						<div>
							<Table className="discount_table" dataSource={this.filter(discount.DiscountCodeList)} rowKey={(record, index) => index} pagination={{ pageSize: 20, }} >
								<Column title="Discount code" dataIndex="discountCode" key="discountCode" />
								<Column title="Discount type" dataIndex="discountType" key="discountType" />
								<Column title="Discount Amount" dataIndex="discountAmount" key="discountAmount" />
								<Column title="Enable/Disable " key="isEnable"
									render={(selectedRow) => (
										<div>
											<Switch defaultChecked={selectedRow.isEnable} onChange={(e => this.selectRowChange(e, selectedRow))} checkedChildren="" unCheckedChildren="" />
										</div>
									)}
								/>
							</Table>
						</div>
					</Spin>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	discount: state.discountReducer,
});

const mapDispatchToProps = {
	dispatchDiscountCreate: (data) => discount.discountCreate(data),
	dispatchDiscountCodeList: () => discount.discountCodeListing(),
	dispatchUpdateDiscount: (data) => discount.updateDisCountCode(data)
};
const translationWrapper = withTranslation()(Form.create()(Discounts));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));

