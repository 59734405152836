import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Select, DatePicker } from "antd";
import "./style.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filter = ({
	selectedEmirate,
	handleSelectedEmirate,
	selectedDeliveryTime,
	handleSelectedDeliveryTime,
	handleDeliveryDateChange,
	selectedShippingDateValue
}) => {
	return (
		<div className="filter-main-section">
			<div className="filter-wrapper">
				<div>
					<Select
						style={{ width: 200 }}
						dropdownClassName="timesheetDropdown"
						placeholder="Select a Emirates"
						optionFilterProp="children"
						className="select-box"
						onChange={(value, option) => handleSelectedEmirate(value, option)}
						value={selectedEmirate}
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="disabled">Select a Emirates</Option>
						<Option labelkey="dubai" value="dubai">Dubai</Option>
						<Option labelkey="abu dhabi" value="abudhabi">Abu Dhabi</Option>
					</Select>
				</div>
				<div>
					<RangePicker
						value={selectedShippingDateValue}
						onChange={(date, dateString) => handleDeliveryDateChange(date, dateString)}
					/>
				</div>
				<div>
					<Select
						style={{ width: 200 }}
						dropdownClassName="timesheetDropdown"
						placeholder="Select a Emirates"
						optionFilterProp="children"
						className="select-box"
						onChange={(value, option) => handleSelectedDeliveryTime(value, option)}
						value={selectedDeliveryTime}
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="disabled">Select a Delivery Time</Option>
						<Option labelkey="9:00 am - 14:00 pm" value="9to14">9:00 am - 14:00 pm</Option>
						<Option labelkey="14:00 pm - 18:00 pm" value="14to18">14:00 pm - 18:00 pm</Option>
					</Select>
				</div>
			</div>
		</div>
	);
};

const routerWrapper = withRouter(withTranslation()(Filter));

export default routerWrapper;
