import React from "react";
import { Modal } from "antd";

function DeleteModal({
	visible,
	confirmLoading,
	modalText,
	handleOk,
	handleCancel,
	title,
	okText,
	cancelText,
	centered,
	customizeFooter
}) {
	return (
		<Modal
			title={title ? title : "title"}
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
			okText={okText ? okText : "Ok"}
			cancelText={cancelText ? cancelText : "Cancel"}
			centered={centered ? true : false}
			footer={customizeFooter ? customizeFooter : undefined}
		>
			<p>{modalText ? modalText : "Description...."}</p>
		</Modal>
	);
}

export default DeleteModal;