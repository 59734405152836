import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import * as notificationActions from "../../../redux/actions/notificationActions";
import "./style.scss";

const { TextArea } = Input;
class Notification extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			title:"",
			message:"",
			validateTrigger: false
		};
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form, dispatchSendNotificationToAllDevices } = this.props;
		form.validateFields((err, values) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				dispatchSendNotificationToAllDevices(values);
			}
		});
	}

	render(){
		const { title, message, validateTrigger } = this.state;
		const { form: { getFieldDecorator }, t } = this.props;
		return (
			<div className="page_content">
				<div className="notification-signup-section">
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="signup-form">
						<Form.Item>
							{getFieldDecorator("title", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: t("Notification.Errors.TitleRequired") }
								],
								initialValue: title
							})(
								<Input placeholder={t("Notification.Title")} />,
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator("message", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: t("Notification.Errors.MessageRequired") }
								],
								initialValue: message
							})(
								<TextArea rows={3} placeholder={t("Notification.Message")} />,
							)}
						</Form.Item>
						<Form.Item className="button-section">
							<Button type="primary" htmlType="submit">
								{t("Notification.Send")}
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = {
	dispatchSendNotificationToAllDevices: (data) => notificationActions.sendNotificationToAllDevices(data)
};

const routerWrapper = withRouter(withTranslation()(Notification));

export default connect(null, mapDispatchToProps)(Form.create()(routerWrapper));
