import * as authService from "../services";
import { dialogAPIError } from "../../utils/dialog";

export const DASHBOARD_COUNT = "DASHBOARD_COUNT";

export const getDashboardCount = (type, data) => ({
	type: DASHBOARD_COUNT,
	payload: { type, data }
});


export function fetchDashboardCount() {
	return  (dispatch) => {
		fetchProductsList(dispatch);
		fetchOrdersList(dispatch);
		fetchCategoriesList(dispatch);
		fetchCustomersList(dispatch);
		discountCodeListing(dispatch);
	};
}

async function fetchProductsList(dispatch) {
	try {
		let products = [];
		const querySnapshot = await authService.queryWithoutIsDeleted("products");
		if (!querySnapshot.empty) {
			querySnapshot.forEach(value => {
				products.push(value.data());
			});
			dispatch(getDashboardCount("products", products.length));
		}
	} catch (error) {
		dialogAPIError(error);
	}
}

async function fetchOrdersList(dispatch) {
	try {
		let orders = [];
		const querySnapshot = await authService.query("orders");
		if (!querySnapshot.empty) {
			querySnapshot.forEach(value => {
				let data = value.data();
				data.id = value.id;
				orders.push(data);
			});
			dispatch(getDashboardCount("orders", orders.length));
		}
	} catch (error) {
		dialogAPIError(error);
	}
}

async function fetchCategoriesList(dispatch) {
	try {
		let categories = [];
		const querySnapshot = await authService.queryWithoutIsDeleted("categories");
		if (!querySnapshot.empty) {
			querySnapshot.forEach(value => {
				categories.push(value.data());
			});
			dispatch(getDashboardCount("categories", categories.length));
		}
	} catch (error) {
		dialogAPIError(error);
	}
}

async function fetchCustomersList(dispatch) {
	try {
		let users = [];
		const querySnapshot = await authService.query("users");
		if (!querySnapshot.empty) {
			querySnapshot.forEach(value => {
				users.push(value.data());
			});
			dispatch(getDashboardCount("customers", users.length));
		}
	} catch (error) {
		dialogAPIError(error);
	}
}

async function discountCodeListing(dispatch) {
	try {
		let discountCodeList = [];
		const querySnapshot = await authService.query("discounts");
		if (!querySnapshot.empty) {
			querySnapshot.forEach(value => {
				let data = value.data();
				data.id = value.id;
				discountCodeList.push(data);
			});
			dispatch(getDashboardCount("discounts", discountCodeList.length));
		}
	} catch (error) {
		dialogAPIError(error);
	}
}
