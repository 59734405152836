/* eslint-disable react/destructuring-assignment */
import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { List, Input, Icon, Spin, Button, Form, Switch } from "antd";
import { connect } from "react-redux";
import * as customersAction from "../../../redux/actions/customersAction";
import "./style.scss";

const { Search } = Input;

class Customers extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			searchedList: null,
			type: "$",
			isEdit: "",
			addDiscountShow: false,
			addDiscountId: null
		};
	}
	componentDidMount() {
		document.title = "IDP - Customers";
		const { dispatchCustomersList } = this.props;
		dispatchCustomersList();
	}
	getCustomerUserList(data) {
		const customerData = data.filter(el => el.type !== "admin");
		customerData.sort(function(x, y){
			if (x.createdDate && y.createdDate) {
				x.createdDate.seconds - y.createdDate.seconds;
			}
		});
		return customerData;
	}
	switchChange(e) {
		if (e) {
			this.setState({ type: "%" });
		} else {
			this.setState({ type: "$" });
		}
	}
	searchProducts(value) {
		const { customers : { customerListData }} = this.props;
		if (value) {
			let string = value.toLowerCase();
			let temp = [];
			if (string) {
				for (let i = 0; i < customerListData.length; i++) {
					if (customerListData[i].name) {
						let data = customerListData[i].name.toLowerCase().match(string) || customerListData[i].email.toLowerCase().match(string);
						if (data !== null) {
							temp.push(customerListData[i]);
						}
					}
				}
				this.setState({ searchedList: temp });
			}
		} else {
			this.setState({ searchedList: null });
		}
	}
	saveDiscount(slectedRow) {
		const { type } = this.state;
		const { form, dispatchupdateCustomerDetail } = this.props;
		form.validateFields((error, value) => {
			if (!error) {
				slectedRow.discountType = type;
				slectedRow.discountAmount =  value.discountAmount;
				dispatchupdateCustomerDetail(slectedRow);
				this.setState({ addDiscountId: "", isEdit: "" });
			}
		});
	}
	deleteDicountFields(selectedRow) {
		delete selectedRow.discountType;
		delete selectedRow.discountAmount;
		const { dispathcDeleteDiscountData } = this.props;

		// const data = customers.customerListData.filter(el => {
		// 	if (el.id === selectedRow.id) {
		// 		el = selectedRow;
		// 	}
		// });
		// console.log(data);
		// this.setState({ searchedList: data });
		dispathcDeleteDiscountData(selectedRow);
	}
	render(){
		const { customers, form: { getFieldDecorator }, dispatchForgotPassword } = this.props;
		const { searchedList, addDiscountId, isEdit } = this.state;
		return (
			<div className="customer_page page_content">
				<div className="customers_title">
					<h1>All Customers</h1>
				</div>
				<div className="customer_search">
					<Search placeholder="Search customer" onChange={(e) => this.searchProducts(e.target.value)} onSearch={(value) => this.searchProducts(value)} />
				</div>
				<div className="main_customers_list">
					<Spin tip="Please wait" spinning={customers.isLoading} size={"large"}>
						<List
							dataSource={searchedList === null ? this.getCustomerUserList(customers.customerListData) : searchedList}
							pagination={{
								pageSize: 20,
							}}
							renderItem={(item, index) => (
								<List.Item className = "customer_details">
									<div className="customer_info">
										<div className="customer_name">
											<span>{item.name}</span>
											<div className="phone_email">
												<div className="email">
													<Icon type="mail" />
													<a href={`mailto:${item.email}`}>{item.email}</a>
												</div>
												<div className="number">
													<Icon type="phone" />
													<a href={`tel:${item.phone}`}>{item.phone}</a>
												</div>
												{isEdit !== `${item.id}${index}` &&  item.discountAmount && item.discountType && <div className="dicountData">
													{item.discountAmount && <span>Discount Amount</span>}
													<span>{item.discountAmount}{item.discountType}</span>
												</div>}
											</div>
										</div>
										<div className="add_btn">
											<Button onClick={() => dispatchForgotPassword(item.email)}>Reset Password</Button>
										</div>
										{item.id !== addDiscountId && !item.discountAmount ? <div className="add_btn">
											<Button onClick={() =>  {
												this.setState({ addDiscountShow: true, addDiscountId: item.id, isEdit: "" });
											}} >ADD</Button>
										</div> : <div className="icon_btn">
											{isEdit !== `${item.id}${index}` &&  item.discountAmount && item.discountType && <div>
												<Button onClick={() => this.setState({ addDiscountId: item.id, isEdit: `${item.id}${index}` })}><Icon type="edit" /></Button>
												<Button onClick={() => this.deleteDicountFields(item)}><Icon type="delete" /></Button>
											</div>}
										</div>
										}
										{addDiscountId === item.id && <div className="Discount_fields">
											<Form onSubmit={this.saveDiscount} className="Discount_fields">
												<div className="type_amount">
													<div className="discount_type">
														<span>Type</span>
														<div className="inner_type">
															<span>$</span>
															<Switch
																defaultChecked={item.discountType === "%" ? true: false}
																checkedChildren=""
																unCheckedChildren=""
																onChange={(e) => this.switchChange(e)} />
															<span>%</span>
														</div>
													</div>
													<div className="discount_amount">
														<span>Discount Amount</span>
														<Form.Item name="discountAmount">
															{getFieldDecorator("discountAmount", {
																rules: [
																	{ required: true, message: "discountAmount Required" }
																],
																initialValue: item.discountAmount
															})(
																<Input placeholder="discount Amount" type="number" onChange={(e) => this.setState({ discountAmount: e.target.value })} />
															)}
														</Form.Item>
													</div>
												</div>
												<div className="buttons">
													<span className="hidden">hidden</span>
													<Button type="submit" onClick={() => this.saveDiscount(item)}><Icon type="check" /></Button>
													<Button type="primary" onClick={() => this.setState({ addDiscountShow: false, addDiscountId: "", isEdit: "" })}>X</Button>
												</div>
											</Form>
										</div>}
									</div>
								</List.Item>
							)}
						/>
					</Spin>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	customers: state.customersReducer,
});

const mapDispatchToProps = {
	dispatchCustomersList: () => customersAction.fetchCustomersList(),
	dispatchupdateCustomerDetail: (data) => customersAction.updateCustomerData(data),
	dispathcDeleteDiscountData: (data) =>  customersAction.deleteDiscoutsData(data),
	dispatchForgotPassword: (email) => customersAction.forgotPassword(email),
};
const translationWrapper = withTranslation()(Form.create()(Customers));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));
