import { createSelector } from "reselect";

export const getUserOrderEmail = createSelector(
	(state) => state.ordersReducer,
	(detail) => detail.userOrdersDetailRes && detail.userOrdersDetailRes.email
);

export const getUserOrderDetail = createSelector(
	(state) => state.ordersReducer,
	(detail) => detail.userOrdersDetailRes
);