import * as authService from "../services";
import { message } from "antd";
import { dialogAPIError } from "../../utils/dialog";

export const DISCOUNT_CREATE_START = "DISCOUNT_CREATE_START";
export const DISCOUNT_CREATE_SUCCESS = "DISCOUNT_CREATE_SUCCESS";
export const DISCOUNT_CREATE_FALIED = "DISCOUNT_CREATE_FALIED";

export const DISCOUNT_LIST_START = "DISCOUNT_LIST_START";
export const DISCOUNT_LIST_SUCCESS = "DISCOUNT_LIST_SUCCESS";
export const DISCOUNT_LIST_FALIED = "DISCOUNT_LIST_FALIED";

export const FETCH_DISCOUNT_CODES = "FETCH_DISCOUNT_CODES";
export const FETCH_DISCOUNT_CODES_SUCCESSFUL = "FETCH_DISCOUNT_CODES_SUCCESSFUL";

export const discountCreateStart = () => ({
	type: DISCOUNT_CREATE_START
});

export const discountCreateSuccess = (data) => ({
	type: DISCOUNT_CREATE_SUCCESS,
	data
});

export const discountCreateFailed = (data) => ({
	type: DISCOUNT_CREATE_FALIED,
	data
});
export const discountCodeListStart = () => ({
	type: DISCOUNT_LIST_START
});

export const discountCodeListSuccess = (data) => ({
	type: DISCOUNT_LIST_SUCCESS,
	data
});

export const discountCodeListFailed = (data) => ({
	type: DISCOUNT_LIST_FALIED,
	data
});

export const discountCodeStart = () => ({
	type: FETCH_DISCOUNT_CODES
});

export const discountCodeSuccess = (data) => ({
	type: FETCH_DISCOUNT_CODES_SUCCESSFUL,
	data
});

export function discountCreate(data) {
	return async(dispatch) => {
		dispatch(discountCreateStart());
		try {
			await authService.addDiscounts("discounts", data)
				.then(() => {
					dispatch(discountCreateSuccess());
					dispatch(discountCodeListing());
				})
				.catch(error => dispatch(discountCreateFailed(error)));
		} catch (error) {
			dispatch(discountCreateFailed(error));
		}
	};
}
export function discountCodeListing() {
	return async(dispatch) => {
		dispatch(discountCodeListStart());
		try {
			let discountCodeList = [];
			const querySnapshot = await authService.query("discounts");
			if (!querySnapshot.empty) {
				querySnapshot.forEach(value => {
					let data = value.data();
					data.id = value.id;
					discountCodeList.push(data);
				});
				dispatch(discountCodeListSuccess(discountCodeList));
			} else {
				const error = "No data Found";
				message.error(error);
				dispatch(discountCodeListFailed({ error }));
				dialogAPIError(error);
			}
		} catch (error) {
			dispatch(discountCodeListFailed(error));
			dialogAPIError(error);
		}
	};
}
export function updateDisCountCode(data) {
	return async(dispatch) => {
		dispatch(discountCreateStart());
		try {
			await authService.updateDiscount("discounts", data)
				.then(() => {
					message.success("discounts Updated Successfully");
				})
				.catch(error => dispatch(discountCodeListFailed(error)));
		} catch (error) {
			dispatch(discountCodeListFailed(error));
		}
	};
}

export function getDiscountCodes(discountCode) {
	return async(dispatch) => {
		dispatch(discountCodeStart());
		try {
			const querySnapshot = await authService.getDiscountCodes();
			let temp = [];
			if (!querySnapshot.empty) {
				querySnapshot.forEach(value => {
					let data = value.data();
					data.id = value.id;
					temp.push(data);
				});
				let filterData = temp.filter((item) => {
					return item.discountCode.toLowerCase() === discountCode.toLowerCase();
				});
				dispatch(discountCodeSuccess(filterData));
			} else {
				const error = "No data Found";
				message.error(error);
				dispatch(discountCodeListFailed({ error }));
			}
		} catch (error) {
			dispatch(discountCodeListFailed(error));
		}
	};
}