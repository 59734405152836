import {
	// DISCOUNT_CREATE_START,
	DISCOUNT_CREATE_SUCCESS,
	// DISCOUNT_CREATE_FALIED,
	DISCOUNT_LIST_START,
	DISCOUNT_LIST_SUCCESS,
	DISCOUNT_LIST_FALIED,
	FETCH_DISCOUNT_CODES,
	FETCH_DISCOUNT_CODES_SUCCESSFUL,
} from "../actions/discountAction";

let initialState = {
	isLoading: false,
	loadingError: null,
	DiscountCodeList: [],
	discountcodeAdd: false,
	isFetchDiscountCodesSuccessful: false,
	discountCodeRes: null
};

const discountReducer = (state = initialState, action) => {
	switch (action.type) {
		case DISCOUNT_LIST_START:
			return { ...state, isLoading: true };
		case DISCOUNT_LIST_SUCCESS:
			return { ...state, isLoading: false, DiscountCodeList: action.data };
		case DISCOUNT_LIST_FALIED:
			return { ...state, isLoading: false, loadingError: action.data, isFetchDiscountCodesSuccessful: false };
		case DISCOUNT_CREATE_SUCCESS:
			return { ...state, discountcodeAdd: false };
		case FETCH_DISCOUNT_CODES:
			return { ...state, isFetchDiscountCodesSuccessful: false };
		case FETCH_DISCOUNT_CODES_SUCCESSFUL:
			return { ...state, isFetchDiscountCodesSuccessful: true, discountCodeRes: action.data };
		default:
			return state;
	}
};
export default discountReducer;