import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Spin, Table, DatePicker } from "antd";
import moment from "moment";
import * as _ from "lodash";
import * as customersAction from "../../../../redux/actions/customersAction";
import * as ordersAction from "../../../../redux/actions/ordersAction";
import "../style.scss";

const { Column } = Table;
const { RangePicker } = DatePicker;

class SalesCustomers extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			ordersList: [],
			customersList: [],
			selectedShippingDate: [],
			selectedShippingDateValue: [],
			backupList: []
		};
	}

	componentDidMount(){
		document.title = "IDP - Sales By Customers";
		const { dispatchCustomersList, dispatchOrdersList } = this.props;
		dispatchCustomersList();
		dispatchOrdersList();
	}

	componentDidUpdate(prevProps) {
		const { orders, customers } = this.props;
		const { customersList } = this.state;
		if (prevProps.orders.fetchOrdersSuccessfull === false && orders.fetchOrdersSuccessfull === true) {
			this.setState({ ordersList: orders.ordersListData, backupList: orders.ordersListData }, () => {
				let filterList = this.getCustomerUserList(customersList);
				this.setState({ filterList: filterList });
			});
		}

		if (prevProps.customers.fetchCustomerSuccessfull === false && customers.fetchCustomerSuccessfull === true) {
			this.setState({ customersList: this.getCustomerUserList(customers.customerListData) });
		}
	}

	getCustomerUserList(data) {
		const { ordersList } = this.state;
		let customerData = data.filter(el => el.type !== "admin");
		customerData.sort(function(x, y){
			if (x.createdDate && y.createdDate) {
				x.createdDate.seconds - y.createdDate.seconds;
			}
		});
		let totalPrice = 0;
		let orderCreatedDate = null;
		let sortedOrder = ordersList.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));
		for (let i in customerData) {
			totalPrice = 0;
			orderCreatedDate = null;
			sortedOrder.filter(el => {
				if (el.uid === customerData[i].uid) {
					orderCreatedDate = el.createdDate;
					totalPrice += el.totalPrice;
				}
			});
			customerData[i].totalPrice = totalPrice;
			customerData[i].orderCreatedDate = orderCreatedDate;
		}
		return customerData;
	}

	filterCustomerList() {
		const { selectedShippingDate, filterList, backupList } = this.state;

		if (selectedShippingDate.length > 0) {
			/* FILTER BY ORDER */
			let newfilterData = _.filter(backupList, obj => {
				let shippingDate = moment(obj.createdDate).format("YYYY-MM-DD");
				if (moment(shippingDate).isBetween(selectedShippingDate[0], selectedShippingDate[1], undefined, "[]")) {
					return obj;
				}
			});

			/* FILTER BY CUSTOMER WHO PLACED ORDER BETWEEN TWO DATES */
			let customerData = filterList.filter(el => el.type !== "admin");
			customerData.sort(function(x, y){
				if (x.createdDate && y.createdDate) {
					x.createdDate.seconds - y.createdDate.seconds;
				}
			});
			let totalPrice = 0;
			let orderCreatedDate = null;
			let sortedOrder = newfilterData.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));
			let tmp = [];
			for (let i in customerData) {
				totalPrice = 0;
				orderCreatedDate = null;
				sortedOrder.filter(el => {
					if (el.uid === customerData[i].uid) {
						orderCreatedDate = el.createdDate;
						totalPrice += el.totalPrice;
						tmp.push(customerData[i]);
					}
				});
				customerData[i].totalPrice = totalPrice;
				customerData[i].orderCreatedDate = orderCreatedDate;
			}
			let uniqBy = _.uniqBy(tmp, (e => e.uid));

			this.setState({ customersList: uniqBy });
		} else {
			this.setState({ customersList: this.getCustomerUserList(filterList) });
		}
	}
	handleDeliveryDateChange(date, dateString) {
		this.setState({ selectedShippingDate: date.length > 0 ? dateString : [], selectedShippingDateValue: date }, () => {
			this.filterCustomerList();
		});
	}

	render(){
		const { customers } = this.props;
		const { customersList, selectedShippingDateValue } = this.state;
		return (
			<div className="page_content">
				<div className="main_customers_list">
					<Spin tip="Please wait" spinning={customers.isLoading} size={"large"}>
						<RangePicker
							value={selectedShippingDateValue}
							onChange={(date, dateString) => this.handleDeliveryDateChange(date, dateString)}
						/>
						<div className="product_listing">
							<Table dataSource={customersList} rowKey={record => record.id} pagination={{ pageSize: 20, }}>
								<Column
									title="Customer Name:"
									dataIndex="name"
									key="name"
								/>
								<Column
									title="Customer Email:"
									dataIndex="email"
									key="email"
								/>
								<Column
									title="Total Purchased Amount"
									dataIndex="totalPrice"
									key="totalPrice"
									defaultSortOrder="descend"
									sorter={(a, b) => a.totalPrice - b.totalPrice}
								/>
							</Table>
						</div>
					</Spin>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	customers: state.customersReducer,
	orders: state.ordersReducer,
});

const mapDispatchToProps = {
	dispatchCustomersList: () => customersAction.fetchCustomersList(),
	dispatchOrdersList: () => ordersAction.fetchOrdersList()
};

const translationWrapper = withTranslation()(SalesCustomers);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));
