import { ORDERS_LIST_START, ORDERS_LIST_SUCCESS, ORDERS_LIST_FALIED, USER_ORDER_DETAIL_START, USER_ORDER_DETAIL_SUCCESS, USER_ORDER_DETAIL_FAILED } from  "../actions/ordersAction";

let initialState = {
	isLoading: false,
	loadingError: null,
	ordersListData: [],
	fetchOrdersSuccessfull: false,
	fetchUserOrdersDetailSuccessful: false,
	userOrdersDetailRes: null,
};

const ordersReducer = (state = initialState, action) => {
	switch (action.type) {
		case ORDERS_LIST_START:
			return { ...state, isLoading: true, fetchOrdersSuccessfull: false };
		case ORDERS_LIST_SUCCESS:
			return { ...state, isLoading: false, fetchOrdersSuccessfull: true, ordersListData: action.data };
		case ORDERS_LIST_FALIED:
			return { ...state, isLoading: false, loadingError: action.data };

		case USER_ORDER_DETAIL_START:
			return { ...state, fetchUserOrdersDetailSuccessful: false };
		case USER_ORDER_DETAIL_SUCCESS:
			return { ...state, fetchUserOrdersDetailSuccessful: true, userOrdersDetailRes: action.data };
		case USER_ORDER_DETAIL_FAILED:
			return { ...state, fetchUserOrdersDetailSuccessful: false };
		default:
			return state;
	}
};
export default ordersReducer;