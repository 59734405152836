import * as authService from "../services";
import { dialogSuccess, dialogAPIError } from "../../utils/dialog";

export function sendNotificationToAllDevices(data) {
	return async() => {
		await authService.handleNotificationToAllDevices(data).then(function() {
			dialogSuccess("Success", "Notification sent successfully!");
		}).catch(function(error) {
			dialogAPIError(error);
		});
	};
}