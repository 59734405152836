import React from "react";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from "../layout/admin";

function PrivateRoute({ component: Component, path, authed }) {
	return (
		<Route
			path={path}
			exact={true}
			render={(props) => authed === true
				?
				<AdminLayout>
					<Component />
				</AdminLayout>
				: <Redirect to={{ pathname: "/", state: { from: props.location }}} />}
		/>
	);
}

export default PrivateRoute;
