import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Spin, Table, DatePicker } from "antd";
import moment from "moment";
import * as _ from "lodash";
import * as productsAction from "../../../../redux/actions/productsAction";
import * as ordersAction from "../../../../redux/actions/ordersAction";
import "../style.scss";

const { Column } = Table;
const { RangePicker } = DatePicker;

class SalesProducts extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			ordersList: [],
			productsData: [],
			productsList: [],
			selectedShippingDate: [],
			selectedShippingDateValue: [],
			convertedProductList: []
		};
	}

	componentDidMount(){
		document.title = "IDP - Sales By Products";
		const { dispatchProductList, dispatchOrdersList } = this.props;
		dispatchProductList();
		dispatchOrdersList();
	}

	componentDidUpdate(prevProps) {
		const { orders, products } = this.props;
		const { productsData, ordersList } = this.state;
		if (prevProps.orders.fetchOrdersSuccessfull === false && orders.fetchOrdersSuccessfull === true) {
			this.setState({ ordersList: orders.ordersListData }, () => {
				this.convertArray(productsData, orders.ordersListData);
			});
		}

		if (prevProps.products.fetchProductsSuccessfull === false && products.fetchProductsSuccessfull === true) {
			this.setState({ productsData: products.productsListData }, () => {
				this.convertArray(products.productsListData, ordersList);
			});
		}
	}

	convertArray(products, orders) {
		if (products.length > 0 && orders.length > 0) {
			let dataProducts = products;
			let totalPrice = 0;
			let quantity = 0;
			let tmp = [];
			// let orderCreatedDate = null;
			let sortedOrder = orders.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));
			for (let i in dataProducts) {
				totalPrice = 0;
				quantity = 0;
				tmp = [];
				sortedOrder.filter(el => {
					el.product.filter((pl) => {
						if (pl.id === dataProducts[i].id) {
							tmp.push({
								orderCreatedDate: el.createdDate,
								totalPrice: pl.productPrice * pl.quantity,
								quantity: pl.quantity
							});
							totalPrice += (pl.productPrice * pl.quantity);
							quantity += pl.quantity;
						}
					});
				});
				dataProducts[i].totalPrice = parseFloat(totalPrice.toFixed(2));
				dataProducts[i].quantity = quantity;
				dataProducts[i].data = tmp;
			}

			this.setState({ productsList: dataProducts, convertedProductList: dataProducts });
		}
	}

	filterProductsList() {
		const { selectedShippingDate, convertedProductList, ordersList, productsData } = this.state;
		if (selectedShippingDate.length > 0) {
			/* FILTER BY ORDER */
			let totalPrice = 0;
			let quantity = 0;
			let newfilterData = _.map(convertedProductList, obj => {
				totalPrice = 0;
				quantity = 0;
				_.filter(obj.data, (item) => {
					let shippingDate = moment(item.orderCreatedDate).format("YYYY-MM-DD");
					if (moment(shippingDate).isBetween(selectedShippingDate[0], selectedShippingDate[1], undefined, "[]")) {
						totalPrice += item.totalPrice;
						quantity += item.quantity;
						// return item;
					}
				});
				// obj.data = filtered;
				obj.totalPrice = parseFloat(totalPrice.toFixed(2));
				obj.quantity = quantity;
				return obj;
			});
			this.setState({ productsList: newfilterData });
		} else {
			this.convertArray(productsData, ordersList);
		}
	}

	handleDeliveryDateChange(date, dateString) {
		this.setState({ selectedShippingDate: date.length > 0 ? dateString : [], selectedShippingDateValue: date }, () => {
			this.filterProductsList();
		});
	}

	render() {
		const { products } = this.props;
		const { productsList, selectedShippingDateValue } = this.state;
		return (
			<div className="page_content">
				<div className="main_customers_list">
					<Spin tip="Please wait" spinning={products.isLoading} size={"large"}>
						<RangePicker
							value={selectedShippingDateValue}
							onChange={(date, dateString) => this.handleDeliveryDateChange(date, dateString)}
						/>
						<div className="product_listing">
							<Table dataSource={productsList} rowKey={record => record.id} pagination={{ pageSize: 20, }}>
								<Column
									title="Product Name:"
									dataIndex="productName"
									key="productName"
								/>
								<Column
									title="Product Quantity:"
									dataIndex="quantity"
									key="quantity"
								/>
								<Column
									title="Product Price:"
									dataIndex="productPrice"
									key="productPrice"
								/>
								<Column
									title="Total Price:"
									dataIndex="totalPrice"
									key="totalPrice"
									defaultSortOrder="descend"
									sorter={(a, b) => a.totalPrice - b.totalPrice}
								/>
							</Table>
						</div>
					</Spin>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	products: state.productsReducer,
	orders: state.ordersReducer,
});

const mapDispatchToProps = {
	dispatchProductList: () => productsAction.fetchProductsList(),
	dispatchOrdersList: () => ordersAction.fetchOrdersList()
};

const translationWrapper = withTranslation()(SalesProducts);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));
