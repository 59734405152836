import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Input, Modal, Form, Select, Button, Upload, message } from "antd";
import * as categoriesAction from "../../../../redux/actions/categoriesAction";
import * as productsAction from "../../../../redux/actions/productsAction";

class EditProductModal extends PureComponent {
	constructor(props) {
		super(props);
		const { values } = this.props;
		this.state = {
			productId: values.id,
			productSequence: values.productSequence,
			productName: values.productName,
			productCategory: values.productCategory,
			url: values.url,
			productPrice: values.productPrice,
			productDescription: values.productDescription,
			image: null,
			imageUrl: false,
			progress: 0,
			productSize: values.productSize,
			productShelfLife: values.productShelfLife,
			productOrigin: values.productOrigin,
			productSubCategory: values.productSubCategory,
			productIngredients: values.productIngredients
		};
		this.editproducthandleOk = this.editproducthandleOk.bind(this);
	}
	componentDidMount() {
		const { dispatchCategoriesList } = this.props;
		dispatchCategoriesList();
	}

	handleChange(e) {
		if (e.currentTarget.value.startsWith(" ")) {
			e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
		}
	}

	editproducthandleOk(e) {
		e.preventDefault();
		const { form } = this.props;
		const { onCancel, values, dispatchImageUpload, dispatchProductUpdate } = this.props;
		const { image, imageUrl } = this.state;

		form.validateFields((error, value) => {
			if (!error) {
				if (value.productSequence !== undefined) {
					values.productSequence = value.productSequence;
				}
				if (value.productName !== undefined) {
					values.productName = value.productName;
				}
				if (value.productCategory !== undefined) {
					values.productCategory = value.productCategory;
				}
				if (value.productSubCategory !== undefined) {
					values.productSubCategory = value.productSubCategory;
				}
				if (value.productPrice !== undefined) {
					values.productPrice = value.productPrice;
				}
				if (value.productSize !== undefined) {
					values.productSize = value.productSize;
				}
				if (value.productShelfLife !== undefined) {
					values.productShelfLife = value.productShelfLife;
				}
				if (value.productOrigin !== undefined) {
					values.productOrigin = value.productOrigin;
				}
				if (value.productIngredients !== undefined) {
					values.productIngredients = value.productIngredients;
				}
				if (value.productDescription !== undefined) {
					values.productDescription = value.productDescription;
				}
				if (value.url !== undefined) {
					values.url = value.url;
				}
				if (imageUrl) {
					const isJpgOrPng = image.type === "image/jpeg" || image.type === "image/png";
					if (!isJpgOrPng) {
						return message.error("You can only upload JPG/PNG file!");
					}
					dispatchImageUpload("edit", image, values);
				} else {
					dispatchProductUpdate(values);
				}
				onCancel();
			}
		});
	}

	uploadImage(data) {
		this.setState({ image: data, imageUrl: true });
	}

	render() {
		const { Option } = Select;
		const { visible, onCancel, categories } = this.props;
		const { form: { getFieldDecorator }, productsList, form } = this.props;
		const { productName, productCategory, productPrice, productDescription, url, validateTrigger, productSequence, productId, productSubCategory, productSize, productShelfLife, productOrigin, productIngredients } = this.state;
		const category = categories && categories.categoriesListData.find(obj => obj.productCategory === productCategory);

		return (
			<div>
				<Modal
					title="Edit Product"
					className="edit_product"
					centered
					visible={visible}
					onOk={this.editproducthandleOk}
					onCancel={onCancel}
					okText="Save"
				>
					<Form onSubmit={this.editproducthandleOk}>
						<label> Product Name </label>
						<Form.Item name="productName" hasFeedback>
							{getFieldDecorator("productName", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Product Name Required" },
									{ max: 40, message: "Input greater than 40 are not allowed" },
								],
								initialValue: productName
							})(
								<Input placeholder="Title" onChange={this.handleChange} />
							)}
						</Form.Item>

						<label>Product Image</label>
						<Form.Item name="upload">
							{getFieldDecorator("url", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Image Upload Required" }
								],
								initialValue: url
							})(
								<Upload name="logo" action={(data) => this.uploadImage(data)} listType="picture" accept="image/*">
									<Button>Product Image</Button>
								</Upload>
							)}
						</Form.Item>
						<label> Category:</label>
						<Form.Item hasFeedback>
							{getFieldDecorator("productCategory", {
								initialValue: productCategory
							})(
								<Select
									placeholder="Select a category"
									value={productCategory}
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange= {(value)  =>  {
										const product = categories.categoriesListData.find(obj => obj.productCategory === value);
										this.setState({ categoryParentId: product.id,  productCategory: value, productSubCategory: undefined });
										form.resetFields(["productSubCategory"]);
									}}
								>
									{
										categories && categories.categoriesListData && categories.categoriesListData.length > 0 && categories.categoriesListData.map((item) => {
											return (
												<Option key={item.productCategory} value={item.productCategory}>{item.productCategory}</Option>
											);
										})
									}
								</Select>
							)}
						</Form.Item>

						<label>Subcategory:</label>
						<Form.Item name="productSubCategory" hasFeedback>
							{getFieldDecorator("productSubCategory", {
								validateTrigger: validateTrigger ? "onChange" : "",
								initialValue: productSubCategory
							})(
								<Select
									value={productSubCategory || undefined}
									placeholder="Select a subcategory"
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={(value) => {this.setState({ productSubCategory: value });}}
								>
									{
										category && category.subCategories && category.subCategories.length > 0 && category.subCategories.map((item, index) => {
											return (
												<Option key={index} value={item}>{item}</Option>
											);
										})
									}
								</Select>,
							)}
						</Form.Item>
						<label> Product Sequence: </label>
						<Form.Item name="productSequence" hasFeedback>
							{getFieldDecorator("productSequence", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Product Sequence Required" },
									{
										validator(_, value) {
											const data = productsList.filter(item => (Number(item.productSequence) === Number(value) && item.productCategory === productCategory
											&& item.productSubCategory === productSubCategory && productId !== item.id));
											if (data.length === 0) {
												return Promise.resolve();
											}
											return Promise.reject("Your added product sequence is already exist!");
										},
									},
								],
								initialValue: productSequence
							})(
								<Input placeholder="Product Sequence" onChange={this.handleChange} />
							)}
						</Form.Item>
						<label> Price: </label>
						<Form.Item name="price" hasFeedback>
							{getFieldDecorator("productPrice", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Product Price Required" },
									{ max: 10, message: "Input greater than 10 are not allowed" },
								],
								initialValue: productPrice
							})(
								<Input placeholder="Price" value={productPrice} onChange={this.handleChange} />
							)}
						</Form.Item>
						<label> Product Size:</label>
						<Form.Item name="productSize" hasFeedback>
							{getFieldDecorator("productSize", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Enter Product Size" },
								],
								initialValue: productSize
							})(
								<Input placeholder="Product Size" onChange={this.handleChange} />,
							)}
						</Form.Item>
						<label> Product Shelf Life:</label>
						<Form.Item name="productShelfLife" hasFeedback>
							{getFieldDecorator("productShelfLife", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Enter Product Shelf Life" },
								],
								initialValue: productShelfLife
							})(
								<Input placeholder="Product Shelf Life ( DAYS )" onChange={this.handleChange} />,
							)}
						</Form.Item>
						<label>Product Origin:</label>
						<Form.Item name="productOrigin">
							{getFieldDecorator("productOrigin", {
								validateTrigger: validateTrigger ? "onChange" : "",
								initialValue: productOrigin
							})(
								<Input placeholder="Product Origin" value={""} onChange={this.handleChange} />,
							)}
						</Form.Item>
						<label>Ingredients:</label>
						<Form.Item name="productIngredients" hasFeedback>
							{getFieldDecorator("productIngredients", { initialValue: productIngredients })(

								<Input.TextArea placeholder="Ingredients" onChange={this.handleChange} />,

							)}
						</Form.Item>
						<label>Description:</label>
						<Form.Item name="Description" hasFeedback>
							{getFieldDecorator("productDescription", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Product Description Required" },
									{ max: 500, message: "Input greater than 500 are not allowed" },
								],
								initialValue: productDescription
							})(
								<Input.TextArea placeholder="Description" value={productDescription} onChange={this.handleChange} />
							)}
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	categories: state.categoriesReducer
});


const mapDispatchToProps = {
	dispatchImageUpload: (action, data, product) => productsAction.uploadImage(action, data, product),
	dispatchProductUpdate: (value) => productsAction.editProduct(value),
	dispatchCategoriesList: () => categoriesAction.fetchCategoriesList(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditProductModal));
