import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Signin from "../pages/auth/signin";
import Home from "../pages/dashboard/home";
import Users from "../pages/dashboard/users";
import Orders from "../pages/dashboard/orders";
import Products from "../pages/dashboard/products";
import Categories from "../pages/dashboard/products/categories";
import Customers from "../pages/dashboard/customers";
import Discounts from "../pages/dashboard/discounts";
import NotFound from "../components/404";
import SalesCustomers from "../pages/dashboard/reports/salescustomers";
import SalesProducts from "../pages/dashboard/reports/salesproducts";
import Notification from "../pages/dashboard/notification";

function RouterComponent({ isLoggedin }) {
	return (
		<Switch history={""}>
			<Route exact={true} path="/" render={() => (
				( isLoggedin ) ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />
			)} />
			<Route exact={true} path="/signin" render={() => (
				isLoggedin ? <Redirect to="/dashboard" /> : <Signin />
			)} />
			<ProtectedRoute path="/dashboard" authed={isLoggedin} component={Home} />
			<ProtectedRoute path="/users" authed={isLoggedin} component={Users} />
			<ProtectedRoute path="/orders" authed={isLoggedin} component={Orders} />
			<ProtectedRoute path="/products" authed={isLoggedin} component={Products} />
			<ProtectedRoute path="/categories" authed={isLoggedin} component={Categories} />
			<ProtectedRoute path="/customers" authed={isLoggedin} component={Customers} />
			<ProtectedRoute path="/reports/salescustomers" authed={isLoggedin} component={SalesCustomers} />
			<ProtectedRoute path="/reports/salesproducts" authed={isLoggedin} component={SalesProducts} />
			<ProtectedRoute path="/discounts" authed={isLoggedin} component={Discounts} />
			<ProtectedRoute path="/notification" authed={isLoggedin} component={Notification} />
			<Route component={NotFound} />
		</Switch>
	);
}

const mapStateToProps = ({ authReducer }) => ({
	isLoggedin: authReducer.loggedIn
});

export default connect(mapStateToProps)(RouterComponent);