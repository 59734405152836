import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as categoriesAction from "../../../../redux/actions/categoriesAction";
import AddCategoryModal from "../categories/addcategory";
import EditCategoryModal from "../categories/editcategory";
import DeleteModal from "../../../../modal/delete-modal";
import { withTranslation } from "react-i18next";
import { Button, Form, Divider, Table, Spin } from "antd";
import "./style.scss";

class Categories extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			ModalText: "Content of the modal",
			confirmLoading: false,
			selectedDataForDelete: null,
			isDeleted: false,
			isEdit: false,
			selectedCategorytName: null,
			selectedRow: "",
		};
		this.showModal = this.showModal.bind(this);
		this.onRow = this.onRow.bind(this);
		this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
		this.handleDeleteOk = this.handleDeleteOk.bind(this);
	}
	componentDidMount() {
		document.title = "IDP - Categories";
		const { dispatchCategoriesList } = this.props;
		dispatchCategoriesList();
	}
	componentDidUpdate(prevProps) {
		const { categories, dispatchCategoriesList } = this.props;
		if (prevProps.categories.categoryDeleteSuccess === false && categories.categoryDeleteSuccess === true) {
			dispatchCategoriesList();
		}
		if (prevProps.categories.categoryEditSuccess === false && categories.categoryEditSuccess === true) {
			dispatchCategoriesList();
		}
		if (prevProps.categories.categoryAddSuccess === false && categories.categoryAddSuccess === true) {
			dispatchCategoriesList();
		}
	}
	onRow(record) {
		return {
			onClick: () => { this.setState({ selectedCategorytName: record }); }
		};
	}
	deleteForm(data) {
		this.setState({
			isDeleted: true,
			confirmLoading: false,
			selectedDataForDelete: data
		});
	}
	handleDeleteOk() {
		this.setState({
			confirmLoading: true,
			isDeleted: false,
		});
		const { dispatchDeleteCategory } = this.props;
		const { selectedDataForDelete } = this.state;
		dispatchDeleteCategory(selectedDataForDelete);
	}
	handleDeleteCancel() {
		this.setState({
			isDeleted: false,
			confirmLoading: false,
			selectedDataForDelete: null
		});
	}
	showModal() {
		this.setState({
			isAdded: true,
		});
	}
	showEditModal(data) {
		this.setState({
			isEdit: true,
			selectedRow: data
		});
	}
	render() {
		const { isAdded, isEdit, isDeleted, selectedRow } = this.state;
		const { categories } = this.props;
		const { Column } = Table;
		return (
			<div className="category_page page_content">
				<div className="category_title">
					<h1>Categories</h1>
					<div>
						<Button type="primary" onClick={this.showModal}>
							Create Category
						</Button>
					</div>
				</div>
				<div className="main_category_list">
					<Spin tip="Please wait" spinning={categories.isLoading} size={"large"}>
						<Table dataSource={categories.categoriesListData} rowKey={record => record.id} onRow={this.onRow} pagination={{ pageSize: 19 }}>
							<Column title="Category" dataIndex="productCategory" key="productCategory" />
							<Column
								align="left"
								title="Action"
								key="edit"
								render={(selectedRow) => (
									<div className="category_button">
										<Button type="primary" onClick={() => this.showEditModal(selectedRow)}>Edit</Button>
										<Divider type="vertical" />
										<Button type="primary" onClick={() => this.deleteForm(selectedRow)}>Delete</Button>
									</div>
								)} />
						</Table>
					</Spin>
					{
						isEdit && (
							<EditCategoryModal
								visible={isEdit}
								onCancel={() => this.setState({ isEdit: false })}
								values={selectedRow}
							/>
						)
					}
					{
						isAdded && (
							<AddCategoryModal
								visible={isAdded}
								onCancel={() => this.setState({ isAdded: false })}
							/>
						)
					}
					{
						isDeleted && (
							<DeleteModal
								title="Are you sure?"
								visible={isDeleted}
								handleOk={this.handleDeleteOk}
								confirmLoading={this.confirmLoading}
								handleCancel={this.handleDeleteCancel}
								modalText="You want to delete this category?"
								centered={true}
								customizeFooter={[
									<Button key="back" onClick={this.handleDeleteCancel}>
										Cancel
									</Button>,
									<Button key="submit" type="danger" loading={this.confirmLoading} onClick={this.handleDeleteOk}>
										Delete
									</Button>,
								]}
							/>
						)
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	categories: state.categoriesReducer
});

const mapDispatchToProps = {
	dispatchCategoriesList: () => categoriesAction.fetchCategoriesList(),
	dispatchDeleteCategory: (data) => categoriesAction.deleteCategory(data),
};

const translationWrapper = withTranslation()(Form.create()(Categories));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));