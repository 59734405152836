import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as categoriesAction from "../../../../redux/actions/categoriesAction";
import * as productsAction from "../../../../redux/actions/productsAction";
import { Input, Button, Modal, Form, Select, Upload, message } from "antd";


class AddProductModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			productSequence: "",
			categoryParentId: "",
			productName: "",
			productCategory: undefined,
			productSubCategory: undefined,
			productImage: "",
			productPrice: "",
			productDescription: "",
			url: "",
			image: null,
			imageUrl: false,
			progress: 0,
			subcategories: [],
			productOrigin: "",
			productSize: "",
			productShelfLife: "",
			productIngredients: ""
		};
		this.addProductHandleOk = this.addProductHandleOk.bind(this);
	}

	componentDidMount() {
		const { dispatchCategoriesList } = this.props;
		dispatchCategoriesList();
	}
	handleChange(e) {
		if (e.currentTarget.value.startsWith(" ")) {
			e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
		}
	}

	addProductHandleOk(e) {
		e.preventDefault();
		const { form, dispatchProductAdd, onCancel, dispatchImageUpload } = this.props;
		const { imageUrl, image } = this.state;
		form.validateFields((error, values) => {
			if (!error) {
				if (imageUrl) {
					const isJpgOrPng = image.type === "image/jpeg" || image.type === "image/png";
					if (!isJpgOrPng) {
						return message.error("You can only upload JPG/PNG file!");
					}
					values.isDeleted = false;
					dispatchImageUpload("add", image, values);
				} else {
					dispatchProductAdd(values);
				}
				setTimeout(() => {
					onCancel();
				}, 1000);
				form.resetFields();
			}
		});
	}

	// Image Upload
	uploadImage(data) {
		this.setState({ image: data, imageUrl: true });
	}

	render() {
		const { Option } = Select;
		const { visible, onCancel, productsList } = this.props;
		const { productName, productCategory, productSubCategory, productPrice, productDescription, validateTrigger, url, productSequence, subcategories, productSize, productShelfLife, productOrigin, productIngredients } = this.state;
		const { form: { getFieldDecorator }, categories, form } = this.props;
		return (
			<div>
				<Modal
					title="Add Product"
					className="edit_product add_product"
					centered
					visible={visible}
					onOk={this.addProductHandleOk}
					onCancel={onCancel}
					okText="Save"
				>
					<Form onSubmit={this.addProductHandleOk}>
						<label> Product Name </label>
						<Form.Item name="productName" hasFeedback>
							{getFieldDecorator("productName", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Product Name Required" },
									{ max: 40, message: "Input greater than 40 are not allowed" },
								],
								initialValue: productName
							})(

								<Input placeholder="Product Name" onChange={this.handleChange} />,


							)}
						</Form.Item>
						<label>Product Image</label>
						<Form.Item name="productImage">
							{getFieldDecorator("url", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Image Upload Required" }
								],
								initialValue: url
							})(
								<Upload name="productImage" action={(data) => this.uploadImage(data)} listType="picture" accept="image/*">
									<Button>Product Image</Button>
								</Upload>
							)}
						</Form.Item>
						<label> Category:</label>
						<Form.Item name="productCategory" hasFeedback>
							{getFieldDecorator("productCategory", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Select Product Category" },
								],
								initialValue: productCategory
							})(
								<Select
									value={productCategory || undefined}
									placeholder="Select a category"
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange= {(value)  =>  {
										const product = categories.categoriesListData.find(obj => obj.productCategory === value);
										this.setState({ categoryParentId: product.id,  productCategory: value, productSubCategory: undefined, subcategories: product.subCategories  });
										form.resetFields(["productSubCategory"]);
									}}
								>
									{
										categories && categories.categoriesListData && categories.categoriesListData.length > 0 && categories.categoriesListData.map((item) => {
											return (
												<Option key={item.productCategory} value={item.productCategory}>{item.productCategory}</Option>
											);
										})
									}
								</Select>
							)}
						</Form.Item>
						<label>Subcategory:</label>
						<Form.Item name="productSubCategory" hasFeedback>
							{getFieldDecorator("productSubCategory", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Select Product subcategory" },
								],
								initialValue: productSubCategory
							})(

								<Select
									value={productSubCategory || undefined}
									placeholder="Select a subcategory"
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={(value) => {this.setState({ productSubCategory: value });}}
								>
									{
										subcategories && subcategories.length > 0 && subcategories.map((item, index) => {
											return (
												<Option key={index} value={item}>{item}</Option>
											);
										})
									}
								</Select>
							)}
						</Form.Item>
						<label> Product Sequence: </label>
						<Form.Item name="productSequence" hasFeedback>
							{getFieldDecorator("productSequence", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Product Sequence Required" },
									{
										validator(_, value) {
											if (productCategory === "" && value != "") {
												return Promise.reject("First Select Product Category!");
											}
											const data = productsList.filter(item => (Number(item.productSequence) === Number(value) && item.productCategory === productCategory && item.productSubCategory === productSubCategory));
											if (data.length === 0) {
												return Promise.resolve();
											}
											return Promise.reject("Your added product sequence is already exist!");
										},
									},
								],
								initialValue: productSequence
							})(

								<Input placeholder="Product Sequence" onChange={this.handleChange} />

							)}
						</Form.Item>
						<label> Price: </label>
						<Form.Item name="productPrice" hasFeedback>
							{getFieldDecorator("productPrice", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Enter Price" },
									{ max: 10, message: "Input greater than 10 are not allowed" },
								],
								initialValue: productPrice
							})(

								<Input placeholder="Price" onChange={this.handleChange} />,
							)}
						</Form.Item>
						<label> Product Size:</label>
						<Form.Item name="productSize" hasFeedback>
							{getFieldDecorator("productSize", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Enter Product Size" },
								],
								initialValue: productSize
							})(

								<Input placeholder="Product Size" onChange={this.handleChange} />,
							)}
						</Form.Item>
						<label> Product Shelf Life:</label>
						<Form.Item name="productShelfLife" hasFeedback>
							{getFieldDecorator("productShelfLife", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Please Enter Product Shelf Life" },
								],
								initialValue: productShelfLife
							})(

								<Input placeholder="Product Shelf Life ( DAYS )" onChange={this.handleChange} />,

							)}
						</Form.Item>
						<label>Product Origin:</label>
						<Form.Item name="productOrigin">
							{getFieldDecorator("productOrigin", {
								validateTrigger: validateTrigger ? "onChange" : "",
								initialValue: productOrigin
							})(

								<Input placeholder="Product Origin" value={""} onChange={this.handleChange} />,


							)}
						</Form.Item>
						<label>Ingredients:</label>
						<Form.Item name="productIngredients" hasFeedback>
							{getFieldDecorator("productIngredients", { initialValue: productIngredients })(

								<Input.TextArea placeholder="Ingredients" onChange={this.handleChange} />,

							)}
						</Form.Item>
						<label>Description:</label>
						<Form.Item name="productDescription" hasFeedback>
							{getFieldDecorator("productDescription", {
								validateTrigger: validateTrigger ? "onChange" : "",
								rules: [
									{ required: true, message: "Write Product Description" },
									{ max: 500, message: "Input greater than 500 are not allowed" },
								],
								initialValue: productDescription
							})(

								<Input.TextArea placeholder="Description" onChange={this.handleChange} />,

							)}
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	products: state.productsReducer,
	categories: state.categoriesReducer
});

const mapDispatchToProps = {
	dispatchProductAdd: (data) => productsAction.addProduct(data),
	dispatchImageUpload: (action, data, product) => productsAction.uploadImage(action, data, product),
	dispatchCategoriesList: () => categoriesAction.fetchCategoriesList(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddProductModal));