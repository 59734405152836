import React from "react";
import { Provider } from "react-redux";
import { Layout } from "antd";
import store from "./redux/store";
import RouterComponent from "./router";
import "./App.scss";
import "./theme.less";

const { Content } = Layout;

function App() {
	return (
		<Provider store={store}>
			<Layout className="main-layout">
				<Content style={{ backgroundColor: "#fff" }}>
					<RouterComponent />
				</Content>
			</Layout>
		</Provider>
	);
}

export default App;
