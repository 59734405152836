import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as productsAction from "../../../redux/actions/productsAction";
import AddProductModal from "../products/addproduct";
import EditProductModal from "../products/editproduct";
import DeleteModal from "../../../modal/delete-modal";
import { withTranslation } from "react-i18next";
import * as categoriesAction from "../../../redux/actions/categoriesAction";
import { Input, Button, Table, Divider, Form, Spin, Select } from "antd";
import "./style.scss";
class Products extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isAdded: false,
			isDeleted: false,
			isEdit: false,
			ModalText: "Content of the modal",
			confirmLoading: false,
			selectedDataForDelete: null,
			selectedRow: {},
			searchedList: null,
			productFilter: "All"
		};
		this.editproductshowModal = this.editproductshowModal.bind(this);
		this.addProductModalShow = this.addProductModalShow.bind(this);
		this.onRow = this.onRow.bind(this);
		this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
		this.handleDeleteOk = this.handleDeleteOk.bind(this);
	}
	componentDidMount() {
		document.title = "IDP - Products";
		const { dispatchProductsList, dispatchCategoriesList } = this.props;
		dispatchProductsList();
		dispatchCategoriesList();
	}
	componentDidUpdate(prevProps) {
		const { products, dispatchProductsList } = this.props;
		if (prevProps.products.productDeleteSuccess === false && products.productDeleteSuccess === true) {
			dispatchProductsList();
			this.setState({
				isDeleted: false,
				confirmLoading: false,
				selectedDataForDelete: null
			});
		}
		if (prevProps.products.productEditSuccess === false && products.productEditSuccess === true) {
			dispatchProductsList();
		}
		if (prevProps.products.productAddSuccess === false && products.productAddSuccess === true) {
			dispatchProductsList();
		}
	}
	onRow(record) {
		return {
			onClick: () => { this.setState({ selectedProductName: record }); }
		};
	}
	deleteForm(data) {
		this.setState({
			isDeleted: true,
			confirmLoading: false,
			selectedDataForDelete: data
		});
	}

	handleDeleteOk() {
		this.setState({
			confirmLoading: true,
			isDeleted: false,
		});
		const { dispatchDeleteProduct } = this.props;
		const { selectedDataForDelete } = this.state;
		dispatchDeleteProduct(selectedDataForDelete);
	}

	handleDeleteCancel() {
		this.setState({
			isDeleted: false,
			confirmLoading: false,
			selectedDataForDelete: null
		});
	}
	editproductshowModal(row) {
		this.setState({
			isEdit: true,
			selectedRow: row
		});
	}
	addProductModalShow() {
		this.setState({
			isAdded: true,
		});
	}
	searchProducts(value) {
		const { products : { productsListData }} = this.props;
		const { productFilter } = this.state;
		if (value) {
			let string = value.toLowerCase();
			let temp = [];
			if (string) {
				for (let i = 0; i < productsListData.length; i++) {
					let data = productsListData[i].productName.toLowerCase().match(string);
					if (data !== null) {
						temp.push(productsListData[i]);
					}
				}
				temp.sort((a, b) => a.productSequence - b.productSequence);
				if (productFilter && productFilter != "All") {
					const filteredProduct = temp.filter(product => product.productCategory == productFilter);
					this.setState({ searchedList: filteredProduct });
					return;
				}
				this.setState({ searchedList: temp });
			}
		} else {
			this.setState({ searchedList: null });
		}
	}
	getProductList(data) {
		data.sort((a, b) => b.productSequence - a.productSequence);
		return data.sort((a, b) => a.productCategory < b.productCategory ? 1 : -1);
	}
	render() {
		const { isAdded, isEdit, isDeleted, selectedRow, searchedList, productFilter } = this.state;
		const { Search } = Input;
		const { products, categoriesListData } = this.props;
		const { Column } = Table;
		const { Option } = Select;
		return (
			<div className="page_content">
				<div className="product_title">
					<h1>All Products</h1>
					<Button type="primary" className="edit_btn" onClick={this.addProductModalShow}>Add Product</Button>
				</div>
				<div className="product_search">
					<Search placeholder="Search product" onChange={(e) => this.searchProducts(e.target.value)} onSearch={(value) => this.searchProducts(value)} />
					<div className="dropdown-container">
						<Select
							size="large"
							style={{ width: "100%", borderRadius: 30 }}
							dropdownClassName="selectDropdow"
							optionFilterProp="children"
							className="select-box"
							onChange={(value) => {
								this.setState({ productFilter: value });
								const allProducts = products.productsListData;
								if (allProducts && allProducts.length > 0 && value != "All"){
									const filteredProduct = allProducts.filter(product => product.productCategory == value);
									this.setState({ searchedList: filteredProduct });
									return;
								}
								this.setState({ searchedList: allProducts });
							}}
							value={productFilter}
						>
							<Option labelkey="All" value="All">All</Option>
							{
								categoriesListData && categoriesListData.categoriesListData && (
									categoriesListData.categoriesListData.map((category, index) => (
										<Option key={index} labelkey={index} value={category.productCategory}>{category.productCategory}</Option>
									))
								)
							}
						</Select>
					</div>
				</div>
				<Spin tip="Please wait" spinning={products.isLoading} size={"large"}>
					<div className="product_listing">
						<Table dataSource={searchedList === null ? this.getProductList(products.productsListData) : searchedList} rowKey={record => record.id} onRow={this.onRow} pagination={{ pageSize: 20, }}>
							<Column
								title="Product Image"
								dataIndex="url"
								key="url"
								render={(url) => (<img src={`${url}`} />)}
							/>
							<Column title="Product Name" dataIndex="productName" key="productName" />
							<Column title="Category" dataIndex="productCategory" key="productCategory" />
							<Column title="SubCategory" dataIndex="productSubCategory" key="productSubCategory" />
							<Column title="Price" dataIndex="productPrice" key="productPrice" />
							<Column
								title="Action"
								key="edit"
								render={(selectedRow) => (
									<div>
										<Button type="primary" onClick={() => this.editproductshowModal(selectedRow)}>Edit</Button>
										<Divider type="vertical" />
										<Button type="primary" onClick={() => this.deleteForm(selectedRow)}>Delete</Button>
									</div>
								)} />
						</Table>
						{
							isEdit && (
								<EditProductModal
									visible={isEdit}
									onCancel={() => this.setState({ isEdit: false })}
									values={selectedRow}
									productsList={products.productsListData}
								/>
							)
						}
						{/* Add Product Modal */}
						{
							isAdded && (
								<AddProductModal
									visible={isAdded}
									onCancel={() => this.setState({ isAdded: false })}
									productsList={products.productsListData}
								/>
							)
						}
					</div>
				</Spin>
				{/* Delete Confirmation Modal */}
				<DeleteModal
					title="Are you sure?"
					visible={isDeleted}
					handleOk={this.handleDeleteOk}
					confirmLoading={this.confirmLoading}
					handleCancel={this.handleDeleteCancel}
					modalText="You want to delete this product?"
					centered={true}
					customizeFooter={[
						<Button key="back" onClick={this.handleDeleteCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="danger" loading={this.confirmLoading} onClick={this.handleDeleteOk}>
							Delete
						</Button>,
					]}
				>
				</DeleteModal>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	products: state.productsReducer,
	categoriesListData: state.categoriesReducer,
});

const mapDispatchToProps = {
	dispatchProductsList: () => productsAction.fetchProductsList(),
	dispatchDeleteProduct: (data) => productsAction.deleteProduct(data),
	dispatchCategoriesList: () => categoriesAction.fetchCategoriesList(),
};
const translationWrapper = withTranslation()(Form.create()(Products));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));