/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Input, Table, Spin, Modal, Button, Select, DatePicker, Form, Popconfirm, Divider, AutoComplete, message, Icon } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import * as momenttz from "moment-timezone";
import * as _ from "lodash";
import * as ordersAction from "../../../redux/actions/ordersAction";
import * as productsAction from "../../../redux/actions/productsAction";
import * as discountAction from "../../../redux/actions/discountAction";
import { getUserOrderEmail, getDiscountCodes, getUserOrderDetail } from "../../../redux/selectors";
import { dialogError, dialogSuccess } from "../../../utils/dialog";
import Filter from "./filter";
import "./style.scss";
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr {...props} />
	</EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);
const { Option } = Select;

class EditableCell extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
		};
		this.renderCell = this.renderCell.bind(this);
	}

	renderCell(form) {
		const { children, dataIndex, record, editTableRow, data, handlechange, handleselect } = this.props;

		const childrens = data.map((item) => (
			<AutoComplete.Option
				value={item.productSubCategory == "Platter" ? item.productName : `${item.productName} ${item.productSize}`}
				key={`${item.id}`}
			>
				{item.productName} {item.productSize}
			</AutoComplete.Option>
		));
		return editTableRow === record.key ? (
			<Form.Item style={{ margin: 0 }}>
				{form.getFieldDecorator(dataIndex, {
					initialValue: record[dataIndex]
				})(
					<AutoComplete
						onChange={(e) => handlechange(e)}
						dataSource={childrens}
						onSelect={(e, opt) => handleselect(e, opt, data)}
						optionLabelProp="value"
					/>
				)}
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24 }}
			>
				{children}
			</div>
		);
	}

	render() {
		const {
			editable,
			dataIndex,
			title,
			record,
			index,
			children,
			handlechange,
			editTableRow,
			handleselect,
			selectedProduct,
			...restProps
		} = this.props;

		return (
			<td {...restProps}>
				{editable ? (
					<EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
				) : (
					children
				)}
			</td>
		);
	}
}


class Orders extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			searchedList: null,
			visible: false,
			selectedProducts: {},
			editModal: false,
			editTableRow: "",
			selectedRows: [],
			selectedClientName: "",
			valueAutoCmplt: "",
			count: 2,
			selectedEmirate: "disabled",
			selectedEmirateItem: [],
			selectedDeliveryTime: "disabled",
			selectedDeliveryTimeItem: [],
			selectedShippingDate: [],
			selectedShippingDateValue: [],
			searchOrderText: "",
			discountCode: ""
		};
		this.handleSelect = this.handleSelect.bind(this);
		this.addProduct = this.addProduct.bind(this);
		this.handleChangeValue = this.handleChangeValue.bind(this);
		this.onchangeDate = this.onchangeDate.bind(this);
	}


	componentDidUpdate(prevProps) {
		const { getDiscountCode, discountReducer } = this.props;
		const { selectedRows } = this.state;
		if (prevProps.discountReducer.isFetchDiscountCodesSuccessful === false && discountReducer.isFetchDiscountCodesSuccessful === true ){
			const response = getDiscountCode;
			if (response.length === 0) {
				this.setDiscountCodeForSpecificUser();
				dialogError("Error", "You entered the invalid promo code!");
			} else if (response.length > 0 && response[0].isEnable === true) {
				let res = response[0];
				dialogSuccess("Success", `You added the ${res.discountType === "$" ? "$" : ""}${res.discountAmount}${res.discountType === "%" ? "%" : ""} discount`);
				this.setState({ selectedRows: { ...selectedRows, discountInfo: res }}, () => {
					let price = selectedRows.originalPrice;
					this.setOrderDiscountCode(price, res);
				});
			} else {
				this.setDiscountCodeForSpecificUser();
				dialogError("Error", "You entered the disabled promo code!");
			}
		}
	}

	updateOrderProduct() {
		const { dispatchOrdersUpdate, userOrderEmail, dispatchSendEmail } = this.props;
		const { selectedRows } = this.state;
		if (selectedRows.product.length > 0) {
			let data = selectedRows;
			let total = data.grandTotal || data.totalPrice;
			let vat = data.newVAT || 0;
			data.totalPrice = Number(total);
			data.product.filter(el => {
				el.quantity = el.value || 1;
				delete el.isInput;
				delete el.value;
				delete el.productDiscountPrice;
			});

			delete data.grandTotal;
			delete data.newVAT;
			delete data.discountPrice;
			delete data.productDiscountTotal;
			dispatchOrdersUpdate(data);

			if (!_.isEmpty(userOrderEmail)) {
				let cretedDate = momenttz().tz("Asia/Dubai");
				let emailData = {
					...data,
					...data.discountInfo,
					receiverEmail: "homedelivery@italiandairyproducts.com",
					createdDate: cretedDate.format("DD MMMM YYYY HH:mm:ss z"),
					VAT: ((selectedRows.discountPrice * 5) / 100).toFixed(2),
					clientEmail: userOrderEmail,
				};

				delete emailData.discountInfo;
				dispatchSendEmail(emailData);
			}

			this.setState({ editModal: false, selectedRows: null });
		} else {
			message.warn("Minimum 1 Product select");
		}
	}
	componentDidMount() {
		document.title = "IDP - Orders";
		const { dispatchOrdersList, dispatchProductsList } = this.props;
		dispatchOrdersList();
		dispatchProductsList();
	}
	getorderList(data) {
		data.forEach((el, index) => el.orderNo = el.orderNo || `IDP-20-0000${index + 1}`);
		data.sort(function(x, y){
			return new Date(x.createdDate) - new Date(y.createdDate);
		});
		let reverseData = [...data];
		reverseData.sort(function(x, y){
			return new Date(y.createdDate) - new Date(x.createdDate);
		});
		return reverseData;
	}
	searchProducts(value) {
		const { orders : { ordersListData }} = this.props;
		const orderData = this.getorderList(ordersListData);
		this.setState({ searchOrderText: value });
		if (value) {
			let string = value.toLowerCase();
			let temp = [];
			if (string) {
				let filterData = _.filter(orderData, obj => {
					if ((obj.clientName && obj.clientName.toLowerCase().includes(string)) ||
						(obj.orderNo && obj.orderNo.toLowerCase().includes(string)) ||
						(obj.shippingDate && obj.shippingDate.toLowerCase().includes(string))
					) {
						return obj;
					}
				});
				this.setState({ searchedList: filterData });
			}
		} else {
			this.setState({ searchedList: null });
		}
		this.resetSelectedFilter();
	}
	showProductsModal(data) {
		this.calculateOrderFormat(data, "seeall");
	}
	handleChange(value, selectedRow) {
		const { dispatchOrdersUpdate } = this.props;
		selectedRow.orderStatus = value;
		dispatchOrdersUpdate(selectedRow);
	}
	editOrderModal(selectRow) {
		const { dispatchUserOrderDetail } = this.props;
		const data = selectRow.product.filter(el => !el.key);
		data.forEach(el => el.value = el.quantity);
		selectRow.product = data;

		dispatchUserOrderDetail(selectRow.uid);
		this.calculateOrderFormat(selectRow, "edit");
	}
	handleAdd() {
		const { count, selectedRows } = this.state;
		const newData = {
			key: count,
			isInput: true
		};
		this.setState({
			editTableRow: count,
			selectedRows: { ...selectedRows, product: [...selectedRows.product, newData] },
			count: count + 1,
		});
	}
	handleDelete(record) {
		const { selectedRows } = this.state;
		const dataSource = { ...selectedRows };
		if (dataSource.product && dataSource.product.length > 0) {
			let filterData = [];
			if (record.id) {
				filterData = dataSource.product.filter(item => item.id !== record.id);
			} else {
				filterData = dataSource.product.filter(item => item.key !== record.key);
			}

			dataSource.product = filterData;
			this.setState({
				editTableRow: false,
			}, () => {
				this.calculateOrderFormat(dataSource, "edit");
			});
		}
	}
	idDisabled(current, selectedRows) {
		const today = moment();
		if (selectedRows.emirates === "Dubai") {
			return current.isBefore(today) || moment(current).format("dddd") === "Friday" || moment(current).format("dddd") === "Monday" || moment(current).format("dddd") === "Wednesday"  || moment(current).format("dddd") === "Friday" || moment(current).format("dddd") === "Saturday";
		} else {
			return current.isBefore(today) ||
			moment(current).format("dddd") === "Sunday" ||
			moment(current).format("dddd") === "Monday" ||
			moment(current).format("dddd") === "Tuesday" ||
			moment(current).format("dddd") === "Thursday" ||
			moment(current).format("dddd") === "Friday" ||
			moment(current).format("dddd") === "Saturday";
		}
	}
	filter(data, text) {
		if (text && data.length) {
			return data.filter(item => {
				return item.productName.toLowerCase().includes(text.toLowerCase());
			});
		}
		return data;
	}
	handleChangeValue(e) {
		this.setState({ valueAutoCmplt: e });
	}
	handleSelect(e, opt, renderItem) {
		const { selectedRows } = this.state;
		let data = renderItem.find(el => el.id === opt.key);
		data = {
			...data,
			productName: data.productSubCategory == "Platter" ? data.productName :  `${data.productName} ${data.productSize}`
		};

		const product = selectedRows.product.filter(el => el.id === data.id);
		const selectfiels = selectedRows.product.find(el => el.key);
		const inputField = selectedRows.product.filter(el => !el.key);

		const selectField = { ...selectfiels, ...data };
		if (!product.length) {
			selectedRows.product = [...inputField, selectField];
		} else {
			message.error("This Product alery exsits !");
		}
	}
	addProduct(record) {
		const { selectedRows, count } = this.state;
		let data = selectedRows;
		data.product.filter(el => {
			if (el.key && el.id) {
				this.setState({ editTableRow: false });
				el.quantity = el.value ? el.value : 1;
				delete el.key;
				delete el.isInput;
			}
			return el;
		});
		this.calculateOrderFormat(data, "edit");
	}
	quantitySub(select) {
		const { selectedRows } = this.state;
		const quantity = select.value ? select.value : 1;
		const updateValue = selectedRows.product.filter(el => {
			if (el.id === select.id) {
				el.value = quantity - 1;
				el.quantity = quantity - 1;
			}
			return el;
		});
		selectedRows.product = updateValue;
		this.calculateOrderFormat(selectedRows, "edit");
	}
	quantityAdd(select) {
		const { selectedRows } = this.state;
		const quantity = select.value ? select.value : 1;
		const updateValue = selectedRows.product.filter(el => {
			if (el.id === select.id) {
				el.value = quantity + 1;
				el.quantity = quantity + 1;
			}
			return el;
		});
		selectedRows.product = updateValue;
		this.calculateOrderFormat(selectedRows, "edit");
	}
	editing(key) {
		const { selectedRows } = this.state;
		return key.editable;
	}
	onchangeDate(date, dateSting) {
		const { selectedRows } =  this.state;
		if (selectedRows) {
			const dateValue = moment(dateSting).format("DD MMMM YYYY");
			this.setState({ selectedRows: { ...selectedRows,  shippingDate: dateValue }});
		}
	}
	closeModel() {
		let { selectedRows } = this.state;
		Modal.destroyAll();
		const data  = selectedRows.product.filter(el => !el.key);
		data.forEach(el => delete el.value);
		this.setState({ selectedRows: "", selectedRowsProduct: "" }, () => {
			setTimeout(() => {
				this.setState({ editModal: false, selectedClientName: "", editTableRow: false });
			}, 100);
		});
	}

	resetSelectedFilter() {
		this.setState({ selectedEmirateItem: [], selectedEmirate: "disabled", selectedDeliveryTimeItem: [], selectedDeliveryTime: "disabled", selectedShippingDate: [], selectedShippingDateValue: [] });
	}

	handleSelectedEmirate(value, option) {
		this.setState({ selectedEmirateItem: option, selectedEmirate: value }, () => {
			this.filterOrderList();
		});
	}

	handleSelectedDeliveryTime(value, option) {
		this.setState({ selectedDeliveryTimeItem: option, selectedDeliveryTime: value }, () => {
			this.filterOrderList();
		});
	}

	handleDeliveryDateChange(date, dateString) {
		this.setState({ selectedShippingDate: date.length > 0 ? dateString : [], selectedShippingDateValue: date }, () => {
			this.filterOrderList();
		});
	}

	filterOrderList() {
		const { searchedList, selectedEmirateItem, selectedEmirate, selectedDeliveryTimeItem, selectedDeliveryTime, selectedShippingDate } = this.state;
		const { orders : { ordersListData }} = this.props;
		const orderData = this.getorderList(ordersListData);
		this.setState({ searchOrderText: "" });
		if (selectedEmirate == "disabled" && selectedDeliveryTime == "disabled" && selectedShippingDate.length == 0) {
			this.setState({ searchedList: null });
		} else {
			if ((selectedEmirateItem.props && selectedEmirateItem.props.labelkey) && (selectedDeliveryTimeItem.props && selectedDeliveryTimeItem.props.labelkey)) {
				let filterData = _.filter(orderData, obj => {
					if ((selectedEmirateItem.props && obj.emirates && obj.emirates.toLowerCase() == selectedEmirateItem.props.labelkey) &&
						selectedDeliveryTimeItem.props && obj.deliveryTime && obj.deliveryTime.toLowerCase() == selectedDeliveryTimeItem.props.labelkey
					) {
						return obj;
					}
				});
				if (selectedShippingDate.length > 0) {
					let newfilterData = _.filter(filterData, obj => {
						let shippingDate = moment(obj.shippingDate).format("YYYY-MM-DD");
						if (moment(shippingDate).isBetween(selectedShippingDate[0], selectedShippingDate[1], undefined, "[]")) {
							return obj;
						}
					});
					this.setState({ searchedList: newfilterData });
				} else {
					this.setState({ searchedList: filterData });
				}
			} else {
				if (selectedEmirate == "disabled" && selectedDeliveryTime == "disabled" && selectedShippingDate.length > 0) {
					let newfilterData = _.filter(orderData, obj => {
						let shippingDate = moment(obj.shippingDate).format("YYYY-MM-DD");
						if (moment(shippingDate).isBetween(selectedShippingDate[0], selectedShippingDate[1], undefined, "[]")) {
							return obj;
						}
					});
					this.setState({ searchedList: newfilterData });
				} else {
					let filterData = _.filter(orderData, obj => {
						if ((selectedEmirateItem.props && obj.emirates && obj.emirates.toLowerCase() == selectedEmirateItem.props.labelkey) ||
							(selectedDeliveryTimeItem.props && obj.deliveryTime && obj.deliveryTime.toLowerCase() == selectedDeliveryTimeItem.props.labelkey)
						) {
							return obj;
						}
					});

					if (selectedShippingDate.length > 0) {
						let newfilterData = _.filter(filterData, obj => {
							let shippingDate = moment(obj.shippingDate).format("YYYY-MM-DD");
							if (moment(shippingDate).isBetween(selectedShippingDate[0], selectedShippingDate[1], undefined, "[]")) {
								return obj;
							}
						});
						this.setState({ searchedList: newfilterData });
					} else {
						this.setState({ searchedList: filterData });
					}
				}
			}
		}
	}

	async calculateOrderFormat(body, fromTo) {
		let data = body;
		let productDiscountTotal = 0;
		data.product.map((item) => {
			item.productDiscountPrice = ((item.productPrice * item.quantity) / 1.05).toFixed(2);
			productDiscountTotal = (Number(item.productDiscountPrice) + Number(productDiscountTotal)).toFixed(2);
			return item;
		});

		let discountPrice = 0;
		if (!_.isEmpty(data.discountInfo)) {
			discountPrice = await this.calculateDiscount(productDiscountTotal, data.discountInfo);
			data.discountPrice = discountPrice.toFixed(2);
			let newVAT = ((discountPrice * 5) / 100).toFixed(2);
			data.newVAT = newVAT;
			data.grandTotal = (Number(discountPrice) + Number(newVAT)).toFixed();
		} else {
			data.VAT = ((data.originalPrice * 5) / 100);
			delete data.newVAT;
			delete data.discountPrice;
			delete data.grandTotal;
		}

		data.productDiscountTotal = productDiscountTotal;

		if (fromTo == "edit") {
			this.setState({
				editModal: true,
				selectedClientName: data.clientName,
				selectedRows: { ...data },
				discountCode: (data.discountInfo && data.discountInfo.discountCode) || ""
			});
		} else if (fromTo == "seeall") {
			this.setState({
				selectedProducts: data,
				selectedClientName: data.clientName,
				visible: true
			});
		} else if (fromTo == "download") {
			this.setState({
				selectedProducts: data,
			}, () => {
				this.downloadInvoice();
			});
		}
	}

	calculateDiscount(price, res) {
		let discount = Number(res.discountAmount);
		let totalPrice = 0;
		if (res.discountType === "%") {
			let finalPrice = (price * discount) / 100;
			totalPrice = (price - finalPrice);
		} else {
			let finalPrice = (price - discount);
			totalPrice = finalPrice;
		}
		return totalPrice;
	}

	handleDiscountCode() {
		const regSignature = /^[a-z0-9]+$/i;
		const { dispatchDiscountCodes } = this.props;
		const { discountCode } = this.state;
		if (_.isEmpty(discountCode) || discountCode.trim() === "" || regSignature.test(discountCode) === false) {
			this.setDiscountCodeForSpecificUser();
			return;
		}
		dispatchDiscountCodes(discountCode);
	}

	setDiscountCodeForSpecificUser() {
		const { getUserOrderDetail } = this.props;
		const { selectedRows } = this.state;

		if (getUserOrderDetail && getUserOrderDetail.discountType && getUserOrderDetail.discountType) {
			const discountInfo = getUserOrderDetail;
			this.setState({ selectedRows: { ...selectedRows, discountInfo: { discountAmount: discountInfo.discountAmount, discountType: discountInfo.discountType }}}, () => {
				this.setOrderDiscountCode(selectedRows.originalPrice, discountInfo);
			});
		} else {
			let data = {
				...selectedRows,
				totalPrice: selectedRows.originalPrice,
				discountInfo: {}
			};
			this.setState({ selectedRows: data }, () => {
				this.calculateOrderFormat(data, "edit");
			});
		}
	}

	setOrderDiscountCode(price, res) {
		let discount = Number(res.discountAmount);
		const { selectedRows } = this.state;
		if (res.discountType === "%") {
			let finalPrice = (price * discount) / 100;
			this.setState({ selectedRows: { ...selectedRows, totalPrice: (price - finalPrice) }}, () => {
				this.calculateOrderFormat(selectedRows, "edit");
			});
		} else {
			let finalPrice = (price - discount);
			this.setState({ selectedRows: { ...selectedRows, totalPrice: finalPrice }}, () => {
				this.calculateOrderFormat(selectedRows, "edit");
			});
		}
	}

	downloadInvoice() {
		const { dispatchSendEmail } = this.props;
		const { selectedProducts } = this.state;
		if (selectedProducts.product.length > 0) {
			let data = {
				...selectedProducts,
				receiverEmail: "homedelivery@italiandairyproducts.com",
				isWeb: true
			};
			if (!_.isEmpty(data.discountInfo)) {
				data.discountAmount = data.discountInfo.discountAmount;
				data.discountType = data.discountInfo.discountType;
			}
			dispatchSendEmail(data);
		}
	}


	render() {
		const {
			searchedList,
			visible,
			selectedProducts,
			selectedClientName,
			selectedRows,
			editModal,
			editTableRow,
			valueAutoCmplt,
			selectedEmirate,
			selectedDeliveryTime,
			selectedShippingDateValue,
			searchOrderText,
			discountCode,
		} = this.state;
		const { Search } = Input;
		const { Column } = Table;
		const { orders, products } = this.props;
		const filteredResult = (valueAutoCmplt !== "") ? this.filter(products.productsListData, valueAutoCmplt) : products.productsListData;
		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell
			}
		};
		const columns = [
			{
				title: "Product Image",
				dataIndex: "url",
				width: "30%",
				render: (url) => (url && <img src={`${url}`} />)
			},
			{
				title: "product Name",
				dataIndex: "productName",
				editable: true
			},
			{
				title: "Category",
				dataIndex: "productCategory",
			},
			{
				title: "Price",
				dataIndex: "productPrice",
			},
			{
				title: "Quantity",
				dataIndex: "",
				render: (data) => {
					const quantity  = data.value ? data.value : 1;
					return <div>
						<Button className="quantity_btn" onClick={() => this.quantitySub(data)}><Icon type="minus" /></Button><span className="quantity_num">{quantity}</span><Button className="quantity_btn" onClick={() => this.quantityAdd(data)}><Icon type="plus" /></Button>
					</div> ;
				}
			},
			{
				title: "operation",
				dataIndex: "operation",
				render: (text, record) => {
					return (<span>
						{editTableRow === record.key && <Button onClick={() => this.addProduct(record)}>Save</Button>}
						{editTableRow === record.key && <Divider type="vertical" />}
						<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record)}>
							<a>Remove</a>
						</Popconfirm>
					</span>);
				}
			},
		];
		const column = columns.map(col => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: record => {
					return {
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handlechange: this.handleChangeValue,
						handleselect: this.handleSelect,
						editTableRow: editTableRow,
						selectedProduct: selectedRows.product,
						data:  this.filter(products.productsListData, valueAutoCmplt)
					};
				},
			};
		});
		return (
			<div className="page_content">
				<div className="product_title">
					<h1>All orders</h1>
				</div>
				<Filter
					selectedEmirate={selectedEmirate}
					handleSelectedEmirate={this.handleSelectedEmirate.bind(this)}
					selectedDeliveryTime={selectedDeliveryTime}
					handleSelectedDeliveryTime={this.handleSelectedDeliveryTime.bind(this)}
					selectedShippingDateValue={selectedShippingDateValue}
					handleDeliveryDateChange={this.handleDeliveryDateChange.bind(this)}
				/>
				<div className="product_search">
					<Search placeholder="Search orders" value={searchOrderText} onChange={(e) => this.searchProducts(e.target.value)} onSearch={(value) => this.searchProducts(value)} allowClear={true} />
				</div>
				<Spin tip="Please wait" spinning={orders.isLoading} size={"large"}>
					<div className="product_listing">
						<Table dataSource={searchedList === null ? this.getorderList(orders.ordersListData) : searchedList} rowKey={(record, index) => index} pagination={{ pageSize: 20, }}>
							<Column
								title="Number"
								key="ind"
								render={(value) => value.orderNo}
							/>
							<Column title="Client Name" dataIndex="clientName" key="clientName" />
							<Column title="Created Date" dataIndex="createdDate" key="createdDate"
								render={(selectedRow) => moment(selectedRow).format("DD MMM YYYY HH:mm:ss")}
							/>
							<Column title="Shipping Date" dataIndex="shippingDate" key="shippingDate" />
							<Column title="Shipping Address" dataIndex="shippingAddress" key="shippingAddress" className="smallWidth" />
							<Column title="Emirates" dataIndex="emirates" key="emirates" />
							<Column title="Total Price" dataIndex="totalPrice" key="totalPrice" render={(selectedRow) => selectedRow.toFixed()} />
							<Column title="Delivery Time" dataIndex="deliveryTime" key="deliveryTime" />
							<Column title="Payment Type" dataIndex="paymentMethod" key="paymentMethod" />
							<Column title="Contact No" dataIndex="clientPhone" key="clientPhone" />
							<Column
								title="Action"
								key="view"
								render={(selectedRow) => (
									<div>
										<Button type="primary" onClick={() => this.showProductsModal(selectedRow)}>See All</Button>
										<Divider type="vertical" />
										<Button type="primary" onClick={() => this.editOrderModal(selectedRow, false)}>Edit</Button>
										{
											(!_.isEmpty(selectedRow.clientEmail)) && (
												<>
													<Divider type="vertical" />
													<Button type="primary" onClick={() => this.calculateOrderFormat(selectedRow, "download")}>Download</Button>
												</>
											)
										}
									</div>
								)}
							/>
							<Column
								title="Order Status"
								key="status"
								render={(selectedRow) => {
									return (<div>
										<Select
											placeholder="Select a Status"
											defaultValue={selectedRow.orderStatus ? selectedRow.orderStatus : "New Order"}
											onChange={value => this.handleChange(value, selectedRow)}
										>
											<Option key={"New_Order"} value={"New_Order"}>New Order</Option>
											<Option key={"Processed"} value={"Processed"}>Processed</Option>
											<Option key={"Delivered"} value={"Delivered"}>Delivered</Option>
											<Option key={"Paid"} value={"Paid"}>Paid</Option>
										</Select>
									</div>);
								}}
							/>
						</Table>
					</div>
				</Spin>
				{
					visible &&
						<Modal
							title={selectedClientName}
							className="product custom_product"
							centered
							visible={visible}
							onCancel={() => this.setState({ visible: false, selectedProducts: {}, selectedClientName: "" })}
							footer={null}
						>
							<div className="product_listing see_all_product_section">
								<div className="tableHeaderRow">
									<div className="tableColumn">
										<span className="tableColumnHeader">Subtotal: </span>
										<span className="tableColumnValue">{selectedProducts.productDiscountTotal} Dhs</span>
									</div>
									{
										selectedProducts.discountPrice &&
										<div className="tableColumn">
											<span className="tableColumnHeader">Discounted Subtotal: </span>
											<span className="tableColumnValue">{selectedProducts.discountPrice} Dhs</span>
										</div>
									}
									{
										<div className="tableColumn">
											<span className="tableColumnHeader">VAT included: </span>
											<span className="tableColumnValue">{selectedProducts.newVAT ? selectedProducts.newVAT : selectedProducts.VAT} Dhs</span>
										</div>
									}
									<div className="tableColumn">
										<span className="tableColumnHeader">Total: </span>
										<span className="tableColumnValue">{selectedProducts.grandTotal ? selectedProducts.grandTotal : selectedProducts.totalPrice} Dhs</span>
									</div>
								</div>
								<Table dataSource={selectedProducts.product} rowKey={(record, index) => record.id + index} pagination={{ pageSize: 20 }}>
									<Column
										title="Product Image"
										dataIndex="url"
										key="url"
										render={(url) => (<img src={`${url}`} />)}
									/>
									<Column title="Product Name" dataIndex="productName" key="productName" />
									<Column title="Category" dataIndex="productCategory" key="productCategory" />
									<Column title="Price" dataIndex="productDiscountPrice" key="productDiscountPrice" />
									<Column title="Quantity" dataIndex="quantity" key="quantity" />
								</Table>
							</div>
						</Modal>
				}
				{
					editModal &&
					<Modal
						title={selectedClientName}
						className="product order_editModal custom_product"
						centered
						visible={editModal}
						onCancel={() => this.closeModel(selectedRows)}
						footer={null}
					>
						<div className="product_listing see_all_product_section">
							<div className="tableHeaderRow">
								<div className="tableColumn">
									<span className="tableColumnHeader">Subtotal: </span>
									<span className="tableColumnValue">{selectedRows.productDiscountTotal} Dhs</span>
								</div>
								{
									selectedRows.discountPrice &&
									<div className="tableColumn">
										<span className="tableColumnHeader">Discounted Subtotal: </span>
										<span className="tableColumnValue">{selectedRows.discountPrice} Dhs</span>
									</div>
								}
								{
									<div className="tableColumn">
										<span className="tableColumnHeader">VAT included: </span>
										<span className="tableColumnValue">{selectedRows.newVAT ? selectedRows.newVAT : selectedRows.VAT} Dhs</span>
									</div>
								}
								<div className="tableColumn">
									<span className="tableColumnHeader">Total: </span>
									<span className="tableColumnValue">{selectedRows.grandTotal ? selectedRows.grandTotal : selectedRows.totalPrice} Dhs</span>
								</div>
							</div>
							<div className="date-discount-section">
								<div className="leftSide">
									<div className="deliveryDate">Delivery Date</div>
									<DatePicker
										hintText="Checkin Date"
										value={selectedRows && selectedRows.shippingDate ? moment(selectedRows.shippingDate) : null}
										disabledDate={(current) => this.idDisabled(current, selectedRows)}
										format={"YYYY/MM/DD"}
										onChange={this.onchangeDate}
									/>
									{/*<div className="rightsideTotal">
										<span>Total:</span>
										<span> {selectedRows.grandTotal ? selectedRows.grandTotal : selectedRows.totalPrice}</span>
									</div>*/}
								</div>
								<div className="discountCodeWrapper">
									<div className="deliveryDate">Discount Code</div>
									<div className="discountCodeInputWrapper">
										<Input placeholder="Discount Code" value={discountCode} onChange={(e) => this.setState({ discountCode: e.target.value })} />
										<Button type="primary" style={{ marginLeft: 5 }} onClick={() => this.handleDiscountCode()}>Save</Button>
									</div>
								</div>
							</div>
							<div className="buttons_addSave">
								<Button disabled={editTableRow} onClick={() => this.handleAdd()} type="primary" style={{ marginBottom: 16 }}>
									Add a row
								</Button>
								<Button disabled={editTableRow} onClick={() => this.updateOrderProduct()}>
									Save Product
								</Button>
							</div>
							<Table
								components={components}
								rowKey={(record, index) => index}
								rowClassName={() => "editable-row"}
								dataSource={selectedRows && selectedRows.product ? selectedRows.product : []}
								columns={column}
							/>
						</div>
					</Modal>
				}
			</div>
		);
	}
}
const mapStateToProps = state => ({
	orders: state.ordersReducer,
	products: state.productsReducer,
	userOrderEmail: getUserOrderEmail(state),
	getDiscountCode: getDiscountCodes(state),
	discountReducer: state.discountReducer,
	getUserOrderDetail: getUserOrderDetail(state)
});

const mapDispatchToProps = {
	dispatchOrdersList: () => ordersAction.fetchOrdersList(),
	dispatchProductsList: () => productsAction.fetchProductsList(),
	dispatchOrdersUpdate: (value) => ordersAction.orderStatusUpdate(value),
	dispatchSendEmail: (data) => ordersAction.sendEmail(data),
	dispatchUserOrderDetail: (uid) => ordersAction.fetchUserOrderDetail(uid),
	dispatchDiscountCodes: (discountCode) => discountAction.getDiscountCodes(discountCode),
};
const translationWrapper = withTranslation()(Form.create()(Orders));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translationWrapper));
