import { CUSTOMERS_LIST_START, CUSTOMERS_LIST_SUCCESS, CUSTOMERS_LIST_FALIED } from  "../actions/customersAction";

let initialState = {
	isLoading: false,
	loadingError: null,
	customerListData: [],
	fetchCustomerSuccessfull: false
};

const customersReducer = (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMERS_LIST_START:
			return { ...state, isLoading: true, fetchCustomerSuccessfull: false };
		case CUSTOMERS_LIST_SUCCESS:
			return { ...state, isLoading: false, fetchCustomerSuccessfull: true, customerListData: action.data };
		case CUSTOMERS_LIST_FALIED:
			return { ...state, isLoading: false, loadingError: action.data };
		default:
			return state;
	}
};
export default customersReducer;